import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CheckMobile, CheckOtp, RegisterUser} from '../../core/interface';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  toggleBoolean :any;
  otpBaseUrl: string;
  userBaseUrl: string;
  userPassword:any;
  username: string = "";
  countryId: string = "";

  constructor(
    private http: HttpClient
  ) {
    this.otpBaseUrl = `${environment.apiEndPoint}/Auth/OtpUser`;
    this.userBaseUrl = `${environment.apiEndPoint}/Auth/User`;
  }

  checkMobileNumber(data: CheckMobile): Observable<object> {
    return this.http.post(`${this.otpBaseUrl}/CheckUserNameAvailability`, data);
  }

  checkSecondaryMobileNumber(data: any): Observable<object> {
    return this.http.post(`${this.userBaseUrl}/CheckUserNumberAvailabilityForSecondaryandPrimary`, data);
  }

  checkOTP(data: CheckOtp): Observable<object> {
    return this.http.post(`${this.userBaseUrl}/VerifyMobileNumber`, data);
  }

  registerUser(data: RegisterUser): Observable<object> {
    return this.http.post(`${this.userBaseUrl}/RegisterPortal`, data);
  }

  VerifyForgotPassword(data: any): Observable<object> {

    return this.http.post(`${this.userBaseUrl}/VerifyForgotPassword`, data);
  }

}
