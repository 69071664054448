import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "@environments/environment";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import {ConfigurationKeys} from "@core/enums";
@Component({
  selector: 'app-notify-modal',
  templateUrl: './notify-modal.component.html',
  styleUrls: ['./notify-modal.component.scss']
})
export class NotifyModalComponent implements OnInit, OnDestroy {
  @Input() displayModal: boolean = false
  @Input() message: string = ''
  @Input() type : string = ''
  @Output() submit = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  sumbitText = this.translateService.instant('notifyMeDetails.notifyAvaliable');
  closeText = this.translateService.instant('notifyMeDetails.close')
  notifyForm: FormGroup;
  predefinedCountries: any[] = [];
  selectedCountry: any;
  selectedTenant : number ;
  PhoneNumberFormat = PhoneNumberFormat;
  phoneNumberlabel: string = 'X';
  SearchCountryField = SearchCountryField;
  CustomCountryISO: any;
  isNumberChecked : boolean = false;
  isEmailChecked : boolean = false;
  @Input() isEmailExist : boolean = false;
  phoneLength: any = 14;
  constructor(private translateService: TranslateService,private fb: FormBuilder, private cd : ChangeDetectorRef) {
    this.createForm();
    this.intializeData()

  }
  ngOnChanges(changes: SimpleChanges) {
     if(changes){
       if(!this.displayModal){
         this.onClose()

       }
     }
  }
  ngOnInit(): void {

  }
  onClick() {
    this.submit.emit(this.notifyForm.getRawValue())
  }
  onClose(){
    this.isNumberChecked = false;
    this.isEmailChecked = false;
    let tenantId = JSON.parse(localStorage.getItem('tenantId') ?? '')
    if(tenantId) {
      this.selectedTenant = tenantId
    }
    this.notifyForm.reset()
    this.close.emit(true);
  }
  isChangeOption(type : string){
    if(type=== 'number'){
      if(!this.isNumberChecked){
        this.notifyForm.controls['phone'].disable()
        this.notifyForm.controls['phone'].setValidators([]);
      }else{
        this.notifyForm.controls['phone'].enable()
        this.notifyForm.controls['phone'].setValidators([Validators.required]);
        this.notifyForm.controls['phone'].updateValueAndValidity()
      }
    }else{
      if(this.isEmailChecked){
        const profile = JSON.parse(localStorage.getItem('profile') ?? '')
        if(profile?.email){
          this.notifyForm.controls['email'].setValue(profile.email);
          this.isEmailExist = true;

        }
      }else{
        this.notifyForm.controls['email'].setValue('');
        //this.isEmailExist = false;
      }
    }
  }
  numberValidator(){
      let number = this.notifyForm.controls['phone'].value?.number
      if(this.selectedTenant && number){
        number = number.replace(/\s/g, '')
        number = number.replace(/[^0-9.]/g, '')
        this.notifyForm.controls['phone'].value.number = number
        // this.notifyForm.controls['phone'].setValue(number);
         if(number.length == this.phoneLength){
           return true;
           // if (this.selectedTenant == 1) {
           //   let allowedUgandaNumber: string[]  = ['77', '73', '75', '78', '70','76'];
           //   let firstDigit = number.substring(0,2)
           //   return allowedUgandaNumber.includes(firstDigit)
           // }else if (this.selectedTenant == 4) {
           //   return number.charAt(0) === '0'
           // }else if (this.selectedTenant == 2) {
           //   return true;
           // }
         }
      }
      return false;
  }
  countryChange(data : any){
    this.selectedTenant = 3;
    if (data.iso2 === 'ug') {
      this.selectedTenant = 1;
      this.phoneLength = 9;
    } else if (data.iso2 === 'gh') {
      this.selectedTenant = 2;
      this.phoneLength = 9;
    } else if (data.iso2 === 'ci') {
      this.selectedTenant = 4;
      this.phoneLength = 10;
    }
    this.numberValidator()
  }
  createForm(){
    this.notifyForm = this.fb.group({
      phone: new FormControl(null),
      email: new FormControl(''),
    });
  }
  intializeData(){
    if(!this.isNumberChecked){
      this.notifyForm.controls['phone'].disable()
    }
    if (environment.isStoreCloud) {
      this.predefinedCountries = [
        {
          name: 'Egypt',
          alpha2Code: 'EG',
          alpha3Code: '',
          numericCode: '818',
          callingCode: '+20',
          maxLength: 10,
        },
      ];
      this.selectedCountry = {
        name: 'Egypt',
        alpha2Code: 'EG',
        itemCode: 'eg',
        alpha3Code: '',
        numericCode: '818',
        callingCode: '+20',
        maxLength: 10,
      };

    } else {
      this.predefinedCountries = [
        CountryISO.Uganda,
        CountryISO.Ghana,
        CountryISO.CôteDIvoire,
      ];
      let tenantId = JSON.parse(localStorage.getItem('tenantId') ?? '')
      if(tenantId){
        this.selectedTenant = tenantId
        if(tenantId === 1){
          this.selectedCountry = this.predefinedCountries [0]
        }else if(tenantId === 2){
          this.selectedCountry = this.predefinedCountries [1]
        }else if(tenantId === 4){
          this.selectedCountry = this.predefinedCountries [2]
        }
        let length = JSON.parse(localStorage.getItem('PhoneLength')?? '')
        if(length){
          this.phoneLength = length;
          for (var i = 1; i < parseInt(this.phoneLength, 0); i++) {
            this.phoneNumberlabel = this.phoneNumberlabel + 'X';
          }
        }
        this.CustomCountryISO = this.selectedCountry;
      }
    }
  }
  ngOnDestroy() {
  }
}
