import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {environment} from "@environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AppDataService, LanguageService, MainDataService, StoreService, TenantService} from "@core/services";
import UtilityFunctions from "@core/utilities/functions";

@Component({
  selector: 'app-mtn-tenant-configuration',
  templateUrl: './tenant-configuration.component.html',
  styleUrls: ['./tenant-configuration.component.scss']
})
export class TenantConfigurationComponent implements OnInit {
  countriesList: any;
  @ViewChild('map', {static: true}) mapElement: ElementRef;
  public searchCountry: string = '';
  allCountries: any = []
  uniqueLanguagesArray: any[];
  userLang: string;
  selectedLang: string | undefined;
  private countryLayer: L.GeoJSON;
  private map: L.Map;
  private highlightedCountryLayer: L.Layer | null = null;
  private _BaseURL = environment.apiEndPoint;

  constructor(private translate: TranslateService, private tenantService: TenantService, @Inject(DOCUMENT) document: Document, private appDataService: AppDataService, private store: StoreService, private mainDataService: MainDataService, private cd: ChangeDetectorRef, private activatedRoute: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: any, private languageService: LanguageService, private router: Router) {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams?.tenantId) {
        this.tenantService.setTenant(queryParams?.tenantId);

      }
    })
  }

  ngOnInit(): void {
    this.fetchGoogleTId();
    if (isPlatformBrowser(this.platformId)) {
      this.userLang = navigator.language.split(/[-_]/)[0];

      localStorage.setItem("browserLanguage", this.userLang)
      this.mainDataService.browserLanguge = localStorage.getItem("browserLanguage");

      this.translate.use(this.mainDataService.browserLanguge);
    }
    //
    const expiry = localStorage.getItem('apiExpiry')
    if (expiry && expiry !== '') {
      if (UtilityFunctions.isTimeExpired(expiry)) {
        this.fetchCountries();
      } else {
        let countries: any = localStorage.getItem('allCountries')
        if (countries && countries !== '') {
          countries = JSON.parse(countries);
          this.updateCountries(countries)
        } else {
          this.fetchCountries()
        }
      }
    } else {
      this.fetchCountries();
    }
    if (isPlatformBrowser(this.platformId)) {

      import('leaflet').then((L) => {
        this.map = L.map('map', {zoomControl: false}).setView([-30, 50], 3,);
        this.map.scrollWheelZoom.disable();
        this.map.doubleClickZoom.disable();
        this.map.dragging.disable();
        this.map.boxZoom.disable();
        this.map.keyboard.disable();
        fetch('./assets/africa.geojson')
          .then(response => response.json())
          .then(data => {

            const africanCountries = data.features.filter((feature: any) => feature.properties.continent === 'Africa');


            L.geoJSON(africanCountries, {
              style: {
                fillColor: '#FFCB05', fillOpacity: 1,

                color: 'grey', // Set the boundary color
                weight: 2,

              },
            }).addTo(this.map).on('add remove', (layer) => {

              this.countryLayer = layer.target;
            });

          });
        this.map.on('mousedown', function (e) {

        });
      });
    }


  }

  changeLang(lang: any): void {
    //
    localStorage.setItem("browserLanguage", lang?.value.split(/[-_]/)[0]);
    this.mainDataService.browserLanguge = localStorage.getItem("browserLanguage")
    this.translate.use(lang?.value);
  }

  searchCountries(event: any) {
    if (this.country.length >= 1) {
      const normalizedCountry = this.removeDiacritics(this.searchCountry.toLowerCase());
      this.countriesList = this.allCountries.filter((item: any) => this.removeDiacritics(item.name.toLowerCase()).includes(normalizedCountry));

    } else if (this.country.length === 0) {
      this.countriesList = this.allCountries;
    }
  }

  removeDiacritics(text: any) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }


  resetHighlight() {
    if (this.highlightedCountryLayer) {
      this.countryLayer.resetStyle(this.highlightedCountryLayer);
      this.highlightedCountryLayer = null;
    }
  }

  country(item: any) {

    fetch('./assets/africa.geojson')
      .then(response => response.json())
      .then(data => {


        const country = data.features.filter((feature: any) => feature.properties.iso_a2 === item.isoCode);
        const otherCountries = data.features.filter((feature: any) => feature.properties.iso_a2 !== item.isoCode);

        this.fillColor(country, '#204E6E');
        this.fillColor(otherCountries, '#FFCB05');


      });
  }

  fillColor(countries: any, color: string) {
    if (isPlatformBrowser(this.platformId)) {
      import('leaflet').then((L) => {
        L.geoJSON(countries, {
          style: {
            fillColor: color, fillOpacity: 1,

            color: 'grey', // Set the boundary color
            weight: 2,

          },
        }).addTo(this.map).on('add remove', (layer) => {

          this.countryLayer = layer.target;
        });
      })
    }
  }

  mouseLeave() {
    if (isPlatformBrowser(this.platformId)) {
      import('leaflet').then((L) => {
        fetch('./assets/africa.geojson')
          .then(response => response.json())
          .then(data => {

            const otherCountries = data.features.filter((feature: any) => feature.properties.continent === 'Africa');


            L.geoJSON(otherCountries, {
              style: {
                fillColor: "#FFCB05", fillOpacity: 1,

                color: 'grey', // Set the boundary color
                weight: 2,

              },
            }).addTo(this.map).on('add remove', (layer) => {

              this.countryLayer = layer.target;
            });

          });
      })
    }

  }

  setTenant(tenantId: any, country?: any) {
    localStorage.setItem('tenantId', tenantId)
    let defaultLanguage: any = country.languages.filter((lang: any) => lang.isDefault)
    this.languageService.setCurrentLang(defaultLanguage[0].code)
    this.router.navigate(['/'])
      .then(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.location.reload();
        }
      });
  }

  countryFlagImages(img: any) {
    return UtilityFunctions.verifyImageURL(img, this._BaseURL);

  }

  navigateToSellerHub() {
    if (isPlatformBrowser(this.platformId)) {
      window.open(environment.merchantURL + '?tenantId=' + this.countriesList[0].tenantId, '_blank');
    }
  }

  fetchCountries() {
    this.mainDataService.getAllCountryTenants().subscribe((res: any) => {
      if (res?.data?.records) {
        localStorage.setItem('allCountries', JSON.stringify(res.data.records))
        this.updateCountries(res.data.records)
      }
    })
  }

  fetchGoogleTId() {
    this.mainDataService.getGoogleAnalyticsTrackingId().subscribe({
      next: (res: any) => {
        localStorage.setItem('GATrackingId', res.data?res.data[0].value:'');
      }, error: (err: any) => {
        console.error(err)
      }
    })
  }

  updateCountries(countries: any) {
    this.countriesList = countries;
    this.allCountries = JSON.parse(JSON.stringify(this.countriesList));
    this.uniqueLanguagesArray = [];

    this.countriesList.forEach((record: any) => {
      record.languages.forEach((language: any) => {

        const existingLanguage = this.uniqueLanguagesArray.find(item => item.name === language.name);
        if (!existingLanguage) {
          this.uniqueLanguagesArray.push({name: language.name, code: language.code});

        }
      });
    });

    this.cd.markForCheck();
    this.cd.detectChanges();

    this.uniqueLanguagesArray.forEach((item: any) => {
      if (this.userLang === item.code) {
        this.selectedLang = item.code
      }
    })
  }
}

//
// = [{
//   flag: "assets/flags/ghana.png",
//   tenantId: "2",
//   countryIso: "GH",
//   countryName: "Ghana"
// },
//   {
//     flag: "assets/flags/uganda.png",
//     tenantId: "1",
//     countryIso: "UG",
//     countryName: "Uganda"
//   },
//   {
//     flag: "assets/flags/Côte d'Ivoire.png",
//     tenantId: "4",
//     countryIso: "CI",
//     countryName: "Côte d'Ivoire"
//   }]
