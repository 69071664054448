export enum ConfigurationKeys {
    CurrencyDecimal = 'CurrencyDecimal',
    CategoryEnNameAppearOnDesign = 'CategoryEnNameAppearOnDesign',
    CategoryArNameAppearOnDesign = 'CategoryArNameAppearOnDesign',
    CountryPhone = 'CountryPhone',
    PhoneLength = 'PhoneLength',
    Currency = 'Currency',
    CustomerAddressLandmarkRequired = 'CustomerAddressLandmarkRequired',
    EmailRequired = "EmailRequired",
    DisableCents = "DisableCents",
    PhoneNumberMask="PhoneNumberMask",
    GATrackingId="GATrackingId"

}
