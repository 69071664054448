<div class="image-bg-tenant">
  <div class="d-flex justify-content-end">
    <button class="mt-4 me-2 sell-on-marketplace" (click)="navigateToSellerHub()">
      <img alt="No Image" src="assets/icons/marketplace-landing.svg">
      Sell on marketplace
    </button>
    <div class="language-dropdown">
      <div class="icon"><img alt="globe" src="assets/icons/globe.svg"></div>
      <p-dropdown #dd4
                  (onChange)="changeLang($event)"
                  [(ngModel)]="selectedLang"
                  [options]="uniqueLanguagesArray"
                  class="language-tenant"
                  optionLabel="name"
                  optionValue="code"
      >
        <ng-template let-item pTemplate="selectedItem">
          {{item?.name}}
        </ng-template>
        <ng-template let-car pTemplate="item">
          <div>
            {{car?.name}}
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="d-flex mobile-display">
    <div class="col-2 scale-150"></div>
    <div class="col-4 d-contents">

      <div class="box-column">
        <div class="content">
          <img alt="Logo" class="mb-3" src="assets/images/logo-marketplace.png"/>
          <h2 class="mb-1 select-country font-normal-mtn"> {{ "landingPage.selectCountry" | translate }}</h2>
          <p class="country-desp mb-3 font-normal-mtn">
            {{ "landingPage.clickOnCountry" | translate }}
          </p>

          <div class="product-search p-inputgroup mb-3">
                <span class="p-inputgroup-addon color"
                ><em class="pi pi-search"></em
                ></span>
            <form
              autocomplete="off"
              class="ng-untouched ng-pristine ng-valid"
              novalidate=""
              style="display: contents"
            >
              <input
                (input)="searchCountries($event)"
                [(ngModel)]="searchCountry"
                [ngModelOptions]="{ standalone: true }"
                autocapitalize="off"
                autocomplete="new-password"
                autocorrect="off"
                class="p-inputtext p-component p-element ng-untouched ng-pristine ng-valid font-normal-mtn"
                pinputtext=""
                placeholder="{{'landingPage.searchCountry' | translate }}"
                spellcheck="false"
                type="text"
              />
            </form>
          </div>

          <div *ngIf="countriesList?.length === 0" class="col-12 text-center">
            <p class="no-found">No country found</p>
          </div>
          <span class="line-border"></span>
          <div class="row countries-scoll justify-content-center">
            <div
              (click)="setTenant(item?.tenantId,item)"
              (mouseenter)="country(item)"
              (mouseleave)="mouseLeave()"
              *ngFor="let item of countriesList"
              class="col-3 text-center mobile-show-countries"
            >
              <img
                [src]="countryFlagImages(item?.flag)"
                alt="No Image"
                alt="No Image"
                class="country-flag"
              />
              <p class="country-name font-normal-mtn">{{ item?.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-5 mobile-display-none">
      <div id="map"></div>
    </div>
    <div class="col-1 scale-150-none"></div>
  </div>
</div>
