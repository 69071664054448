import { PageFilter } from "./FilterPage";

export interface ProductDetails {
  productId?: number;
  specsProductId: number;
  PriceId?: number;
  ShopId?: number;
  price?: number;
  quantity: number;
}

export interface OrderDetails {
  orderId: number;
  orderAmount: number;
  productDetails: ProductDetails[];
}

export interface GetAllOrderDetailRes {
  logisticFee?: string
  orderId?: number;
  qty?: number;
  createdOn?: string;
  updateOn?: string;
  shippingAddress?: ShippingAddress;
  shipments?: ShipmentsModel[];
  items: OrderProductItem[];
  channelName?: string;
  totalQtyOrdered?: string;
  customerFirstName?: string;
  customerLastName?: string;
  deliveryOption?: string;
  customerEmail?: string;
  customerPhone?: string;
  totalItemCount?: string;
  formatedGrandTotal?: string;
  formatedShippingAmount?: string;
  shippingAmount?: any;
  paymentTitle?: string;
  grandTotal?: number;
  orderCurrencyCode?: string;
  status?: string;
  orderStatus?: OrderStatus;
  isDispatched?: boolean;
  transactionId?: string;
  transactionStatus?: string;
  orderItemsAmount?: number;
  CommissionAmount?: number;
  isMarketplace?: boolean;
  isReviewed?: boolean;
  currencyCode?: string;
  secretCode?: string
}
export enum OrderStatus {
  Initial = -1,
  OrderPlaced = 0,
  Processing = 1,
  Delivered = 2,
  Cancelled = 3,
  RequestReturn = 4,
  InTransit = 5,
  Returned = 6,
  UnderProcessing = 7
}
export enum SubOrderDetailStatus
{
  Pending = 0,
  Processing = 1,
  Complete = 2,
  Cancellation = 3,
  CancelledByUser = 4,
  Returned = 5,
  RequestReturn = 6,
  UnderProcessing = 7,
  OrderPlaced = 8
}
export enum OrderItemStatus
{
  Pending = 0,
  Processing = 1,
  Delivered = 2,
  Cancelled = 3,
  CancelledByUser = 4,
  Returned = 5,
  RequestReturn = 6,
  Placed = 7
}
export interface ShipmentsModel {
  productId: number;
  trackNumber: string;
  status: string;
}

export interface OrderProductItem {
  name?: string;
  sku?: string;
  qtyOrdered: number;
  formatedPrice?: string;
  price: any;
  productImage?: string;
  productId?: number;
  specsProductId?: number;
  thumbnailImageUrl?: any;
  thumbnailImages?: any;
  orderItemStatusId: number;
  orderItemStatus: string;
  oldPrice: number;
  selectedReturnReason: any;
  returnReasonDescription: string;
  selectedImageFile: File;
  subOrderDetailsId?: number;
  selectedFileName: any;
}

export interface ShippingAddress {
  address: string[];
  countryName: string;
  city: string;
  streetAddress: string;
}

export interface GetAllRefundReasonRes {
  id: number;
  reason: string;
}

export interface CancelOrderReq {
  orderId: number
  cancelledReasonId?: number | null
  cancelAllOrder: boolean
  cancelledItems: CancelledItem[]
  cancelReasonDescription: string | null
}

export interface CancelledItem {
  ItemId: number
  cancelledReasonId?: number
  CancelReasonDescription?: string | null
}
export interface ReturnOrder {
  orderId: number
  refundAllOrder: boolean
  refundReasonDescription: string
  refundOrderItems: ReturnItems[]
}
export interface ReturnItems {
  ItemId?: number,
  RefundReasonId?: number,
  RefundReasonDescription: string,
  evidenceFile: string
}


export class RefundOrderReq {
  RefundReasonId: number | undefined;
  OrderId: number | undefined;
  ItemList: Refunditem[] | undefined;
}

export class Refunditem {
  SpecsProductId: number | undefined;
  Qty: number | undefined;
}

export interface OrderProductDataRes {
  productId: number;
  productName?: string;
  productSku?: string;
  productPrice?: number;
  productImage?: string;
  thumbnailImageUrl?: any;
  thumbnailImages?: any;
}

export interface GetAllOrderRes {
  id: number;
  status: OrderStatus;
  total: number;
  createdOn: string;
  transferReferenceId?: string;
  totalDeliveryCost: number;
  isDispatched: boolean;
  addressId?: number;
  orderStatus?: string;
  statusName?: string;
  customerId?: string;
  itemCount: number;
  currencyId: number;
  orderAmount: number;
  orderDate?: string;
  updateOn?: string;
  tenantId?: number;
  orderId: number;
  sellerName?: string;
  shopId: number;
  orderProducts?: OrderProductDataRes[];
  deliveryfees: number;
  fromMarketPlace: boolean;
  channelName: string;
  totalQtyOrdered?: number;
  customerFirstName?: string;
  customerLastName?: string;
  formatedGrandTotal?: string;
  incrementId?: string;
  customerEmail?: string;
  grandTotal?: string;
  orderCurrencyCode?: string;
  isConfirmed?: boolean;
  currencyCode?: string;
  thumbnailImageUrl?: any;
}

export interface RequestOrderRefundReq {
  orderId?: number;
  refundReasonDescription?: string;
}
export interface GetAllCancelledReasonReq {
  id?: number;
  reason?: string;
}
export interface GetAllReturnReason {
  id?: number;
  reason?: string;
}
export class GetCustomerOrderReq extends PageFilter {

}

export enum UserTypeOfCancellation
{
  Consumer = 1,
  Merchant = 2,
  Operation = 3,
  All=4
}
