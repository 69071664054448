import { Component } from '@angular/core';
import {Browser} from "leaflet";
import {CookieService} from "ngx-cookie-service";
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
  constructor(private cookieService: CookieService) {
  }
goBack(){
  const localMaintenanceTenant = localStorage.getItem('maintenanceModeTenant')
  localStorage.clear();
  localStorage.setItem('maintenanceModeTenant', localMaintenanceTenant || '')
  this.cookieService.delete('accessToken', '/');
  setTimeout(() => {
    window.location.reload();
  }, 1000);
  }
}
