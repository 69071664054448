import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  private baseUrl = environment.apiEndPoint;

  constructor(private http: HttpClient) {
  }

  deleteCustomerWishList(data: any) {
    return this.http.post(`${this.baseUrl}/product/CustomerWishList/DeleteCustomerWishList`, data);
  }

  getAllCustomerWishList() {
    return this.http.get(`${this.baseUrl}/Product/CustomerWishList/GetAllCustomerWishList?TopNumber=100`);
  }

}
