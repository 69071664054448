import {GuidGenerator} from "@core/services";
import {environment} from "@environments/environment";

export default class UtilityFunctions {

  static verifyImageURL(url: string, baseUrl: string): string {
    if (url) {
      if (url.startsWith('/')) url = url.substring(1);
      const sub = url.substring(0, url.indexOf("/"));
      if (sub.toLowerCase().includes("images")) return `${baseUrl}/${url}`;
      else {
        return `${baseUrl}/Images/${url}`;
      }
    } else {
      if (environment.isStoreCloud) {
        return "assets/images/placeholder.png";
      } else {
        return "assets/images/mtn-alt.png";
      }
    }

  }

  static getKeyNameByValue(value: number, object: any){
    const key= Object.keys(object).find(key => object[key] === value);
    if(key) {
      // add space between the words of key
      return key.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    return '';
  }

  static assignSessionId(): string{
    const sessionId = localStorage.getItem('sessionId')
    if(!sessionId || sessionId === '') {
      const guid = GuidGenerator.newGuid()
      localStorage.setItem('sessionId', guid);
      return guid;
    }
    return sessionId ?? '';
  }

  static assignConsentSessionId(): string{
    const sessionId = localStorage.getItem('consumer-consent-sessionId')
    if(!sessionId || sessionId === '') {
      const guid = GuidGenerator.newGuid()
      localStorage.setItem('consumer-consent-sessionId', guid);
      return guid;
    }
    return sessionId ?? '';
  }

  static isTimeExpired(expiryTime: any){
    const date1 = new Date(expiryTime);
    const date2 = new Date();

    const time1 = date1.getTime();
    const time2 = date2.getTime();

    const differenceInMinutes = (time2 - time1) / (1000 * 60);
    return differenceInMinutes >= 30;
  }
}
