<ng-container *ngIf="!isMobileView || !isMobileTemplate; else mobileView">
  <div
    class="mtn-product-card"
    (click)="productDetails(product?.productId)"
    *ngIf="isLayoutTemplate"
    style="width: inherit"
  >
    <div class="d-flex flex-row col-12 mtn-product-card__top-labels">
      <ng-container *ngIf="topBadge && topBadge.name !== ''">
        <div
          class="mtn-product-card__label mtn-product-card__left-top {{
            topBadge.className
          }}"
        >
          {{ topBadge.translatedName | translate }}
        </div>
      </ng-container>
      <div
        class="mtn-product-card__right-top cursor-pointer"
        (click)="addToWishlist($event, product); $event.stopPropagation()"
      >
        <img
          *ngIf="!product?.isLiked && !isLiked"
          src="assets/icons/mobile-heart-icon.svg"
          width="15"
          height="15"
          alt="Heart Thin icon"
          title="Heart Thin icon"
        />

        <img
          *ngIf="product?.isLiked || isLiked"
          src="assets/icons/filled-heart-icon.svg"
          width="15"
          height="15"
          alt="Heart Thin icon"
          title="Heart Thin icon"
        />
      </div>
    </div>
    <div>
      <img
        ngSrc="{{ getProductImages(product.masterImageUrl ? product.masterImageUrl : product?.thumbnailImages.length ? product?.thumbnailImages[0] : null) }}"
        alt="No Image"
        [width]="100"
        [height]="100"
        class="mtn-product-card__product-image"
        (error)="errorHandler($event)"
      />
    </div>
    <div class="d-flex flex-row-reverse" *ngIf="false">
      <div class="mtn-product-card__label mtn-product-card__blue-label">
        {{ "productDetails.details.bestseller" | translate }}
      </div>
    </div>
    <div class="mtn-product-card__product-details">
<!--      <div class="d-none">-->
<!--        <p-rating-->
<!--          [(ngModel)]="rating"-->
<!--          [cancel]="false"-->
<!--          [readonly]="true"-->
<!--          [stars]="5"-->
<!--        ></p-rating>-->
<!--        <span class="mtn-product-card__product-details__rating-count">-->
<!--          (738)-->
<!--        </span>-->
<!--      </div>-->

      <div class="d-flex flex-row justify-content-between"
      [ngClass]="
          product.quantity
      ? 'justify-content-space-evenly'
      : 'justify-content-end'
      ">


        <div class="flex-column " *ngIf=" screenWidth>767">
            <div *ngIf="product.stockStatus === 'Low Stock'" class="mtn-product-card__product-details__low-stock">
              {{"productDetails.details.only" | translate}} {{product.quantity}}
              <ng-container *ngIf="product.quantity > 1"> {{"productDetails.details.leftInStockMultiple" | translate}} </ng-container>
              <ng-container *ngIf="product.quantity === 1"> {{"productDetails.details.leftInStock" | translate}} </ng-container>
            </div>

        </div>
        <div class="flex-column" *ngIf="bottomBadge && bottomBadge.name !== ''">
          <div class="mtn-product-card__label {{ bottomBadge.className }}">
            {{ bottomBadge.translatedName | translate }}
          </div>
        </div>
      </div>

      <div class="mtn-product-card__product-details__title">
        {{ product.productName }}
      </div>


      <div class="mtn-product-card__product-details__price" *ngIf="!product.salePriceValue">
        {{product.currencyCode ?? currencyCode}} {{disableCent === "false" ? (product.priceValue | number: "1." + decimalValue + "-" + decimalValue)
        :
        (product.priceValue) }}
      </div>
      <ng-container *ngIf="product.salePriceValue">
        <div class="d-flex justify-content-between">
        <div class="mtn-product-card__product-details__price">
          {{product.currencyCode ?? currencyCode}} {{disableCent === "false" ? (product.salePriceValue | number: "1." + decimalValue + "-" + decimalValue)
          :
          (product.salePriceValue) }}
        </div>

        </div>
        <div class="d-inline">
          <div class="mtn-product-card__product-details__sale-price float-left">

            {{product.currencyCode}} {{disableCent === "false" ? (product.priceValue | number: "1." + decimalValue + "-" + decimalValue)
            :
            (product.priceValue) }}
          </div>
          <div class="mtn-product-card__label mtn-product-card__green-label" *ngIf="product?.salePercent">
            {{ getSalePricePercent(product.salePercent) }}%
            {{ "productDetails.details.off" | translate }}
          </div>
        </div>
        <div
          class="mtn-product-card__product-details__low-stock"
          *ngIf="
            product.proSchedulingId &&
            product.stockPerSKU &&
            product.quantity < product.stockPerSKU &&
            screenWidth <= 767
          "
        >
          {{ "productDetails.details.only" | translate }}
          {{ product.quantity }}
          {{ "productDetails.details.leftInStock" | translate }}
        </div>
      </ng-container>
    </div>
    <div class="mtn-product-card-footer">
      <ng-container *ngIf="profile && isShowNotifyFeature; else loggedOut">
        <button
          class="mtn-product-card__card-buttons mtn-product-card__card-buttons__buy-btn w-100"
          *ngIf="!product.soldOut"
          (click)="addItemToCart('buyNow', $event)"
        >
          {{ "productDetails.details.buyNow" | translate }}
        </button>

        <button
          class="mtn-product-card__card-buttons mtn-product-card__card-buttons__cart-btn w-100"
          *ngIf="!product.soldOut"
          (click)="addItemToCart('addCart', $event)"
        >
          <img *ngIf="!scConfig" alt="No Image" src="assets/icons/shopping-cart.svg" />
          <img *ngIf="scConfig" alt="No Image" src="assets/icons/shopping-cart-sc.svg" />
          {{ "productDetails.details.addToCart" | translate }}
        </button>
        <button
          class="mtn-product-card__card-buttons mtn-product-card__card-buttons__cart-btn w-100"
          *ngIf="product?.soldOut"
          (click)="notifyMe(); $event.stopPropagation()"
        >
          <img alt="No Image" src="assets/icons/notify-me.svg" />
          {{ "productDetails.details.notifyMe" | translate }}
        </button>
      </ng-container>
      <ng-template #loggedOut>
        <button
          class="mtn-product-card__card-buttons mtn-product-card__card-buttons__buy-btn w-100"
          [disabled]="product?.soldOut"
          [ngStyle]="{ opacity: product.soldOut ? '0.5' : '' }"
          (click)="addItemToCart('buyNow', $event)"
        >
          {{ "productDetails.details.buyNow" | translate }}
        </button>

        <button
          class="mtn-product-card__card-buttons mtn-product-card__card-buttons__cart-btn w-100"
          [disabled]="product?.soldOut"
          [ngStyle]="{ opacity: product.soldOut ? '0.5' : '' }"
          (click)="addItemToCart('addCart', $event)"
        >
          <img *ngIf="!scConfig" alt="No Image" src="assets/icons/shopping-cart.svg" />
          <img *ngIf="scConfig" alt="No Image" src="assets/icons/shopping-cart-sc.svg" />
          {{ "productDetails.details.addToCart" | translate }}
        </button>
      </ng-template>
    </div>
  </div>

  <div class="old-product-card" *ngIf="!isLayoutTemplate">
    <div
      (click)="productDetails(product?.productId)"
      class="product-card shadow-1 cursor-pointer"
    >
      <div *ngIf="product?.soldOut" class="out-stock">
        <span>{{ "productCard.soldOut" | translate }}</span>
      </div>

      <img
        alt="No Image"
        (error)="errorHandler($event)"
        *ngIf="!product?.thumbnailImages?.length"
        [src]="getProductImages(product.masterImageUrl)"
      />

      <img
        alt="No Image"
        (error)="errorHandler($event)"
        *ngIf="product?.thumbnailImages?.length"
        [src]="getProductImages(product?.thumbnailImages[0])"
      />
      <div class="title mt-3">{{ product.productName }}</div>
      <div class="price-container mt-2">
        <div
          *ngIf="product.salePriceValue || product.salePriceValue > 0"
          class="price mx-1"
        >
          {{ product.currencyCode }}

          {{disableCent === "false" ? (product.salePriceValue | number: "1." + decimalValue + "-" + decimalValue)
          :
          (product.salePriceValue) }}
        </div>
      </div>
      <div class="price-container">
        <div
          [ngClass]="{
            'cross-class': product.salePriceValue || product.salePriceValue > 0
          }"
          class="price mx-1"
        >
          {{ product.currencyCode }}
          {{disableCent === "false" ? (product.priceValue | number: "1." + decimalValue + "-" + decimalValue)
          :
          (product.priceValue) }}
        </div>
      </div>

      <div class="rating mt-2">
        <em class="star pi pi-star-fill"></em>
        <div class="rate mx-1">{{ product.rate | number : "1.1-2" }}</div>
        <div class="rating-number">
          ({{ product.count | number : "1.1-2" }})
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #mobileView>
  <div
    class="mtn-product-card-mobile"
    (click)="productDetails(product?.productId)"
    *ngIf="isLayoutTemplate"
    style="width: inherit"
  >
    <div class="row p-0 m-0 mtn-product-card-mobile__top-labels">
      <div class="col-8">
        <ng-container *ngIf="topBadge && topBadge.name !== ''">
          <div
            class="mtn-product-card-mobile__label mtn-product-card-mobile__left-top mtn-product-card__left-top {{
              topBadge.classNameMobile
            }}"
          >
            {{ topBadge.translatedName | translate }}
          </div>
        </ng-container>
      </div>
      <div class="col-4 mtn-product-card-mobile__heart-holder">
        <div
          class="mtn-product-card-mobile__right-top cursor-pointer"
          (click)="addToWishlist($event, product); $event.stopPropagation()"
        >
          <img
            *ngIf="!isLiked"
            src="assets/icons/mobile-heart-icon.svg"
            width="15"
            height="15"
            alt="Heart Thin icon"
            title="Heart Thin icon"
          />

          <img
            *ngIf="isLiked"
            src="assets/icons/filled-heart-icon.svg"
            width="15"
            height="15"
            alt="Heart Thin icon"
            title="Heart Thin icon"
          />
        </div>
      </div>
    </div>
    <div>
      <img
        ngSrc="{{ getProductImages(product.masterImageUrl) }}"
        alt="No Image"
        [width]="100"
        [height]="100"
        class="mtn-product-card-mobile__product-image"
        (error)="errorHandler($event)"
      />
      <ng-container *ngIf="!product.soldOut">
        <div class="mtn-product-card-mobile__cart-button" [ngClass]="{'already-added-cart':quantityInCart > 0}" *ngIf="!showMobileCartClicked" (click)="mainAddToCartButton('addCart', $event)" >
          <img
            *ngIf="quantityInCart === 0"
            src="assets/icons/mobile-cart-icon.svg"
            width="20"
            height="18"
            alt="Heart Thin icon"
            title="Heart Thin icon"
          />
          <ng-container *ngIf="quantityInCart > 0">
            <span class="cart-badge">{{quantityInCart}}</span>
            <img
              src="assets/icons/mobile-cart.svg"
              width="20"
              height="18"
              alt="Heart Thin icon"
              title="Heart Thin icon"
            />
          </ng-container>

        </div>
        <div class="mtn-product-card-mobile__cart-clicked-button d-flex justify-content-between" *ngIf="showMobileCartClicked">
          <img *ngIf="quantityInCart === 1" (click)="addItemToCart('addCart', $event, 'minus')"
            src="assets/icons/delete-red.svg" class="mtn-product-card-mobile__cart-clicked-button__product-cart-delete" alt="No Image"/>
          <span *ngIf="quantityInCart > 1"
            class="mtn-product-card-mobile__cart-clicked-button__product-cart-action" (click)="addItemToCart('addCart', $event, 'minus')">-</span>
          <span class="mtn-product-card-mobile__cart-clicked-button__product-cart-count">{{quantityInCart}}</span>
          <span class="mtn-product-card-mobile__cart-clicked-button__product-cart-action" (click)="addItemToCart('addCart', $event)">+</span>
        </div>

      </ng-container>

    </div>

    <div class="mtn-product-card-mobile__product-details">
<!--      <div class="mtn-product-card-mobile__product-details__holder">-->
<!--        <p-rating-->
<!--          [(ngModel)]="rating"-->
<!--          [cancel]="false"-->
<!--          [readonly]="true"-->
<!--          [stars]="1"-->
<!--          class="card-rating"-->
<!--        ></p-rating>-->
<!--        <span class="mtn-product-card-mobile__product-details__rating">-->
<!--          4.3-->
<!--        </span>-->
<!--        <span class="mtn-product-card-mobile__product-details__rating-count">-->
<!--          (738)-->
<!--        </span>-->
<!--      </div>-->


      <div class="mtn-product-card-mobile__product-details__title">
        {{ product.productName }}
      </div>
      <div
        class="mtn-product-card-mobile__product-details__price"
        *ngIf="!product.salePriceValue"
      >
      <span class="mtn-product-card-mobile__product-details__price__currencycode">  {{ product.currencyCode }} </span>
        {{disableCent === "false" ? (product.priceValue | number: "1." + decimalValue + "-" + decimalValue)
        :
        (product.priceValue) }}
      </div>
      <ng-container *ngIf="product.salePriceValue">
        <div class="d-flex justify-content-between">
          <div class="mtn-product-card-mobile__product-details__price">
            <span class="mtn-product-card-mobile__product-details__price__currencycode">    {{ product.currencyCode }} </span>
            {{disableCent === "false" ? (product.salePriceValue | number: "1." + decimalValue + "-" + decimalValue)
            :
            (product.salePriceValue) }}
          </div>
          <div
            class="mtn-product-card-mobile__product-details__low-stock"
            *ngIf="
              product.proSchedulingId &&
              product.stockPerSKU && product.quantity < product.stockPerSKU &&
              screenWidth > 767
            "
          >
            {{ "productDetails.details.only" | translate }}
            {{ product.quantity }}
            {{ "productDetails.details.leftInStock" | translate }}
          </div>
        </div>
        <div class="d-inline-flex">
          <div class="mtn-product-card-mobile__product-details__sale-price">
            <!-- {{ product.currencyCode }} -->
            {{disableCent === "false" ? (product.priceValue | number: "1." + decimalValue + "-" + decimalValue)
            :
            (product.priceValue) }}
          </div>
          <div class="mtn-product-card-mobile__label mtn-product-card-mobile__green-label" *ngIf="product?.salePercent">
            {{ getSalePricePercent(product.salePercent) }}%
            {{ "productDetails.details.off" | translate }}
          </div>
        </div>
        <div
          class="mtn-product-card-mobile__product-details__low-stock"
          *ngIf="
            product.proSchedulingId &&
            product.stockPerSKU && product.quantity < product.stockPerSKU &&
            screenWidth <= 767
          "
        >
          {{ "productDetails.details.only" | translate }}
          {{ product.quantity }}
          {{ "productDetails.details.leftInStock" | translate }}
        </div>
      </ng-container>

      <div *ngIf="product.stockStatus === 'Low Stock'" class="mtn-product-card__product-details__low-stock">
        {{"productDetails.details.only" | translate}} {{product.quantity}} {{"productDetails.details.leftInStock" | translate}}
      </div>
    </div>
  </div>
</ng-template>

<app-notify-modal
  [isEmailExist]="isEmailExist"
  [displayModal]="displayNotifyModal"
  (close)="displayNotifyModal = false"
  (submit)="onSubmitNotify($event)"
></app-notify-modal>
<app-success-info-modal
  [titleMessage]="successTitleMessage"
  [bodyMessage]="successBodyMessage"
  [displayModal]="displaySuccessModal"
  (cancel)="onCancel()"
></app-success-info-modal>
