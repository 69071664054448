export class ContactInfo {
  fullName: string = "";
  phoneNumber: string = "";
  countryCode: string = "";
  email: string = "";
  gender: string = "";
  description: string = "";
  addressLatLng: string = "";
  addressLabel: string = "";
  city: string = "cairo";
  building: string = "";
  plotNumber: string = "";

}

export class RequestDelivery {
  action: string = "";
  countryCode: string = "";
  vehicleType: string = "DELIVERY_BIKE";
  paymentMode: string = "MOBILE_WALLET";
  pickupContactInfo: ContactInfo = new ContactInfo();
  dropOffContactInfo: ContactInfo = new ContactInfo();

}

export class PriceEstimation {
  action: string = "";
  countryCode: string = "";

  origin: string = "";
  destination: string = "";

}
