import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import UtilityFunctions from "@core/utilities/functions";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-flash-sale-modal',
  templateUrl: './flash-sale-modal.component.html',
  styleUrls: ['./flash-sale-modal.component.scss']
})
export class FlashSaleModalComponent {
  @Input() displayModal: boolean = false;
  @Input() data: any;
  hrs:any;
  minutes:any;
  seconds:any;
  interval: any;

  constructor(private cd: ChangeDetectorRef) {


  }


  @Output() submit = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();
  ngOnInit(){
    this.hrs=Number(this.data?.time?.split(':')[0]);
    this.minutes=Number(this.data?.time?.split(':')[1]);
    this.seconds=Number(this.data?.time?.split(':')[2]);
      this.startTimer();

  }
  onCancel() {
    localStorage.setItem('visited','true');
    clearInterval(this.interval);
    this.cancel.emit(true);
  }
  onVisit(){
    localStorage.setItem('visited','true');
    clearInterval(this.interval);
    this.submit.emit(true);
  }
    startTimer() {

        this.interval=setInterval(() => {
            if (this.seconds > 0) {
                this.seconds-=1;
            }
            else{
              this.seconds=59;
              if (this.minutes > 0) {
                this.minutes -= 1;
              }
              else{
                this.minutes= 59;
                if(this.hrs>0){
                  this.hrs-=1;
                }
                else{
                  this.hrs-=0;
                  this.minutes=0;
                  this.seconds=0;
                  clearInterval( this.interval);
                }

              }
            }
            this.cd.detectChanges();
        }, 1000);

    }

  getImagesURl(data:any){
    return UtilityFunctions.verifyImageURL(data, environment.apiEndPoint);
  }

}
