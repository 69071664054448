import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${environment.apiEndPoint}/Payment`;
  }

  createTransaction(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/Transaction/CreateTransaction`, data);
  }
  createMultipleTransactionDetails(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/TransactionDetails/CreateMultipleTransactionDetails`, data);
  }
  GetOrderTransaction(id: any): Observable<object> {
    return this.http.get(`${this.baseUrl}/Transaction/GetTransactionByOrderWithoutFilter/${id}`);
  }
  GetTransactionById(id: any): Observable<object> {
    return this.http.get(`${this.baseUrl}/Transaction/GetTransactionById/${id}`);
  }
}
