import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

import {environment} from 'src/environments/environment';
import {Language, ResponseModel, PaginatedResult} from '../interface';
import {MainDataService} from "./main-data.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language = new BehaviorSubject('en');
  private numLang:any  = localStorage.getItem('lang')?? 'en';

  constructor(private http: HttpClient, private mainDataService: MainDataService, private translate: TranslateService) {
  }

  getLanguage(): Observable<{ languages: Language[] | undefined }> {
    return this.http.get<ResponseModel<PaginatedResult<Language>>>(`${environment.apiEndPoint}/Tenant/Language/GetAllLanguage`)
      .pipe(map((res: any) => {
        let setLanguage;
        const allLanguages = res.data.records
        res.data?.records.forEach((childObj: any) => {

          let lng = this.mainDataService.browserLanguge ? this.mainDataService.browserLanguge : localStorage.getItem("browserLanguage")

          if (lng == childObj.code) {

            setLanguage = childObj.code

          }


        });

        let defaultLanguage: any = res.data?.records.filter((lang: any) => lang.isDefault)

        if(!defaultLanguage.length && res.data?.records.length) {
          const nonDefaultLanguage = res.data?.records[0]
          localStorage.setItem('lang', nonDefaultLanguage.code);
          this.translate.setDefaultLang(nonDefaultLanguage.code);
          this.translate.use(nonDefaultLanguage.code);
        } else {
          const lang: any = setLanguage ?? defaultLanguage[0]?.code;
          localStorage.setItem('lang', lang);
          this.translate.setDefaultLang(lang);
          this.translate.use(lang);
        }
        return {
          languages: res?.data?.records
        };
      }));
  }
  get currentLang(): string {
    let numLang : any = localStorage.getItem('numLang')
    return numLang ? numLang : this.numLang;
  }

  setCurrentLang(lang: string): void {
    localStorage.setItem('numLang',lang)
  }
}
