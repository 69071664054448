import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import {filter, Observable} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {PermissionService, StoreService} from "@core/services";
@Injectable()
export class RouteInterceptor implements HttpInterceptor {
  userDetails: any;
  sessionId: string | null;
  fullUrl: any;
  tenantId: any;
  productId: any;
  isGoogleAnalytics: boolean = false;

  constructor(private router: Router,private route:ActivatedRoute,private $gaService: GoogleAnalyticsService,private store: StoreService,private permissionService: PermissionService) {
    this.userDetails = this.store.get('profile');
    this.sessionId = localStorage.getItem('sessionId');
    this.fullUrl = window.location.href;
    this.route.queryParams.subscribe((params: any) => {
      this.tenantId = params.tenantId;
    });
    this.route.params.subscribe(params => {
      this.productId = params.id;
    });

  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Intercept request here
    this.isGoogleAnalytics = this.permissionService.hasPermission('Google Analytics');

    if(this.isGoogleAnalytics && this.permissionService.getTagFeature('page_visited')){
      this.$gaService.event("page_visited", '','',1,true,{"product_ID":this.productId?this.productId:null,"user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated',"session_ID":this.sessionId,"tenant_ID":this.tenantId,"visitedURL":this.fullUrl});
    }


    // Pass the request to the next handler
    return next.handle(request);
  }
}
