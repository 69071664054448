import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, Subject, tap, throwError } from 'rxjs';
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';

import { environment } from "src/environments/environment"

import jwt_decode from "jwt-decode";

import {AuthService, AuthTokenService, StoreService} from "../services";
import {RefreshTokenViewModel} from '../interface';
import {isPlatformBrowser} from "@angular/common";


@Injectable()
export class AppInterceptor implements HttpInterceptor {
  headers: any;
  userToken: any = '';
  tenantId: any;
  guestToken: string;
  clonedRequest: any;
  timeInterval: any;
  XXSRFTOKEN: string = '';
  decoded: any;
  helper = new JwtHelperService();
  isRefreshTokenCalled: boolean = false;
  isExpired = false;
  called = false;


  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private store: StoreService,
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private cookieService: CookieService,
    private authTokenService: AuthTokenService) {
    this.guestToken = '';
    this.headers = {
      authorization: ' ',
      token: '',
      lang: '',
      tenantId: '',
      currency: '',
      accept: 'application/json',
      contentType: 'application/json',
      allowOrigin: '*',
      allowHeaders: 'Cookie, Cache-Control, Host, User-Agent, Accept, token, Authorization, currency, lang, origin, x-requested-with, content-type, Accept-Encoding',
      allowMethods: 'PUT, GET, POST, DELETE, PATCH, OPTIONS',
      contentPolicy: "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
    };
    this.getTimeInterval();

  }

  addAHeaders(request: any) {


    this.userToken = this.cookieService.get('authToken');
    if(!this.userToken) {
      let profile: any = localStorage.getItem('profile')
      if(profile && profile !== '') {
        profile = JSON.parse(profile)
        this.userToken = profile.authToken.replace('bearer ', '')
        const decoded: any = jwt_decode(this.userToken);

        let days: any = (decoded.exp / (60 * 60 * 24 * 1000)).toFixed(
          0
        );
        const dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + parseInt(days));
        this.cookieService.set('authToken', this.userToken, {
          expires: dateNow,
          path: '/',
          sameSite: 'Strict',
        });
      }
    }
    if (request.url.includes("RefreshToken") ||
      request.url.includes("Login") ||
      request.url.includes("ForgotPassword")) {

      this.headers.Authorization = '';
    } else {
      this.headers.Authorization = (this.userToken == '') ? '' : `Bearer ${this.userToken}`;
    }


    this.tenantId = localStorage.getItem('tenantId');
    if (this.tenantId) {
      this.headers.tenantId = this.tenantId;
    }

    this.headers.lang = localStorage.getItem('lang') ?? 'en';
    let currency = localStorage.getItem('currency')?.toString();
    if (currency) {
      this.headers.currency = currency;
    } else {
      this.headers.currency = 'UGX';
    }
    this.headers.XXSRFTOKEN = this.store.get('XXSRFTOKEN') || localStorage.getItem('XXSRFTOKEN');
    if (this.headers.XXSRFTOKEN == undefined) {
      this.headers.XXSRFTOKEN = '';
    }
    const mappedContentType = request.url.includes('connect/token') || request.url.includes('ContactForm/SubmitContact')
      ? 'application/x-www-form-urlencoded'
      : this.headers.contentType;

    if (environment.isMarketPlace) {
      this.clonedRequest = request.clone({

        headers: request.headers
          .set('Authorization', this.headers.Authorization)
          .set('token', this.headers.token)
          .set('tenantId', this.headers.tenantId)
          .set('lang', this.headers.lang)
          .set('currency', this.headers.currency)
          .set('Accept', this.headers.accept)
          .set('Content-Type', mappedContentType)
          .set('X-XSRF-TOKEN', this.headers.XXSRFTOKEN)
          .set('Content-Security-Policy', this.headers.contentPolicy)
        ,
        withCredentials: true
      });
    } else {
      this.clonedRequest = request.clone({

        headers: request.headers
          .set('Authorization', this.headers.Authorization)
          .set('token', this.headers.token)
          .set('lang', this.headers.lang)
          .set('currency', this.headers.currency)
          .set('Accept', this.headers.accept)
          .set('Content-Type', this.headers.contentType)
          .set('X-XSRF-TOKEN', this.headers.XXSRFTOKEN)
          .set('Content-Security-Policy', this.headers.contentPolicy)
        ,
        withCredentials: true
      });
    }


    return this.clonedRequest;
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      this.userToken = this.cookieService.get('authToken');
      let refreshToken: any = localStorage.getItem('refreshToken');

      let model = new RefreshTokenViewModel();
      model.AuthToken = this.userToken;
      model.RefreshToken = refreshToken;

      return this.authService.refreshToken(model).pipe(
        tap((res: any) => {
          this.refreshTokenInProgress = false;

          let token = res.data.authToken;
          this.decoded = jwt_decode(token);

          let days: any = (this.decoded.exp / (60 * 60 * 24 * 1000)).toFixed(0);

          const dateNow = new Date();
          dateNow.setDate(dateNow.getDate() + parseInt(days));
          this.cookieService.set('authToken', token, { expires: dateNow, path: '/', sameSite: 'Strict' });
          localStorage.setItem('refreshToken', res.data.refreshToken);

          this.tokenRefreshedSource.next();

        }),
        catchError((error): any => {

          this.refreshTokenInProgress = false;
          this.messageService.add({
            severity: 'error',
            summary: "Oops",
            detail: 'Something went wrong,please login again'
          });
          this.signOut();
        }));

    }
  }


  handleResponseError(error: any, request?: any, next?: any) {

    if (isPlatformBrowser(this.platformId)) {
      if (error.status == 401 || error.status == 0) {

        if ((this.router.url.includes('login') || this.router.url.includes('register')) && error.status != 401 && !error.url.includes('market.momo.africa')) {
          this.messageService.add({
            severity: 'error',
            detail: error?.error?.message ? error.error.message : 'Something went wrong',
          });
        } else {
          this.signOut();
        }
      } else if (!error.url.includes('/GetCategoriesParentAndChildProductsNew/') && !error.message.includes('Invalid Data')) {
        this.messageService.add({
          severity: 'error',
          detail: error?.error.message ? error.error.message : 'Something went wrong',
        });

      }

      return throwError(error);

    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    request = this.addAHeaders(request);

    return next.handle(request).pipe(catchError((error): any => {
      return this.handleResponseError(error, request, next);
    }));
  }

  signOut(): void {
    const tenantId = localStorage.getItem('tenantId') ?? environment.defaultTenant
    const maintenanceMode = localStorage.getItem('maintenanceMode') ?? 'false'
    const saveCookie = localStorage.getItem('save_cookie') ?? ''
    const localMaintenanceTenant = localStorage.getItem('maintenanceModeTenant')
    localStorage.clear();
    localStorage.setItem('maintenanceModeTenant', localMaintenanceTenant || '')
    this.setStoreData();
    this.authTokenService.authTokenSet("");

    this.cookieService.delete('authToken', '/');
    this.store.set('cartProducts', []);
    localStorage.setItem('secondaryDefault', 'false')
    localStorage.setItem('sessionId', '');
    localStorage.setItem('addedProducts', '');
    localStorage.setItem('cartId', '');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('auth_enc');
    localStorage.setItem('tenantId', tenantId);
    localStorage.setItem('maintenanceMode', maintenanceMode);
    localStorage.setItem('save_cookie', saveCookie);
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = '/'
    }
  }

  NotFound(): void {
    this.router.navigate(['/not-found']);
  }

  getTimeInterval(): void {
    if (this.store.get('timeInterval')) {
      this.store.subscription('timeInterval')
        .subscribe({
          next: (res: Date) => {
            if (res) {
              this.timeInterval = res;
            }
          },
          error: (err: any) => {
            console.error(err);
          }
        });
    }
  }

  setStoreData(): void {
    if (this.store.localStoreNames.length) {

      this.store.set('refreshToken', null);
      this.store.set('profile', null);
      this.store.set('cartProducts', []);
      this.store.set('favouritesProducts', []);
      this.store.set('compareProducts', []);
      this.store.set('socialAccount', null);
      this.store.set('XXSRFTOKEN', null);
      this.store.set('notifications', {
        notifications: [],
        unreadNotifications: 0
      });
      this.store.set('checkoutData', {
        shipping: null,
        payment: null,
        promo: null,
        steps: null,
        profile: null,
        orderId: null
      });
    } else {

      localStorage.setItem('refreshToken', '');
      localStorage.setItem('timeInterval', '');
      localStorage.setItem('TenantId', '');
      localStorage.setItem('userPhone', '');
      localStorage.setItem('profile', '');
      localStorage.setItem('cartProducts', JSON.stringify([]));
      localStorage.setItem('favouritesProducts', JSON.stringify([]));
      localStorage.setItem('compareProducts', JSON.stringify([]));
      localStorage.setItem('XXSRFTOKEN', '');
    }
  }


}
