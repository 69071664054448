import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-mtn-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  @Input() title: string = '';
  @Input() categoryID: number | undefined;
  @Input() featureProduct: number | undefined;
  @Input() topNumber: number | undefined;
  @Input() fetchStatus: string;

  constructor(private router: Router, private translate: TranslateService) {
  }

  ngOnInit(): void {
    /**/

  }


  goToCategories() {
    if (this.featureProduct) {
      this.router.navigate([
        `/category/${this.featureProduct}&${this.topNumber}&${this.translated(this.title, false)}`,
      ]);
    } else {
      this.router.navigate([`/category/${this.categoryID}`]);
    }
  }

  translated(name: string, translate: boolean = true) {
    if (!name) return ""
    name = name?.replace(/\s/g, '');

    if(translate)return name?.toLowerCase() ? 'featureType.' + name.toLowerCase() : '';
    else return name
  }
}
