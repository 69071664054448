import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainLandingComponent} from "@pages/landing/components/main-landing/main-landing.component";
import {AuthGuard} from "@core/guards/auth.guard";
import {PagenotfoundComponent} from "@shared/components/pagenotfound/pagenotfound.component";

const routes: Routes = [
  {
    path: '',
    component: MainLandingComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'product/:id/:channelId',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/product-details/product-details.module').then((m) => m.ProductDetailsModule)
          }
        ]
      },
      {
        path: 'promotion/:id',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/category-products/category-products.module').then((m) => m.CategoryProductsModule)
          }
        ]
      },
      {
        path: 'category/:id',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/category-products/category-products.module').then((m) => m.CategoryProductsModule)
          }
        ]
      },
      {
        path: 'categories',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/categories/categories.module').then((m) => m.CategoriesModule)
          }
        ]
      },
      {
        path: 'search',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchModule)
          }
        ]
      },
      {
        path: 'cart',
        loadChildren: () => import('./pages/cart/cart.module').then((m) => m.CartModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'merchants',
        loadChildren: () => import('./pages/merchants/merchants.module').then((m) => m.MerchantsModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./pages/order/orders/orders.module').then((m) => m.OrdersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'order/:id',
        loadChildren: () => import('./pages/order/order-details/order-details.module').then((m) => m.OrderDetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'order-refund',
        loadChildren: () => import('./pages/order/order-refund/order-refund.module').then((m) => m.OrderRefundModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/sign-in/sign-in.module').then((m) => m.SignInModule),

      },
      {
        path: 'contact-us',
        loadChildren: () => import('./pages/footer-pages/contact-us/contact-us.module').then((m) => m.ContactUsModule),

      },
      {
        path: 'about-us',
        loadChildren: () => import('./pages/footer-pages/about-us/about-us.module').then((m) => m.AboutUsModule),

      },
      {
        path: 'reset-password',
        loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
      {
        path: 'otp',
        loadChildren: () => import('./pages/auth/otp/otp.module').then((m) => m.OtpModule),
      },
      {
        path: 'update-password',
        loadChildren: () => import('./pages/auth/update-password/update-password.module').then((m) => m.UpdatePasswordModule),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/auth/change-password/change-password.module').then((m) => m.ChangePasswordModule),
      },
      {
        path: 'checkout',
        loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then((m) => m.RegisterModule),

      },
      {
        path: 'not-found',
        loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
      },
      {
        path: 'wishlist',
        loadChildren: () => import('./pages/wishlist/wishlist.module').then((m) => m.WishlistModule),
      },
      {
        path: 'merchants-livestream',
        loadChildren: () => import('./pages/merchant-livestream/merchant-livestream.module').then((m) => m.MerchantLivestreamModule),
      },
      {
        path: 'categories-list',
        loadChildren: () => import('./pages/category-list-mobile/category-list.module').then((m) => m.CategoryListModule)
      },
      {
        path: '**', pathMatch: 'full',
        component: PagenotfoundComponent
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', scrollOffset: [0, -20]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
