import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {UserConsent} from "@core/interface";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl: string;
  username: string = "";
  countryISO: any = ""
  private scrollTop = new BehaviorSubject<any>(false);
  scrollTrigger = this.scrollTop.asObservable();

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${environment.apiEndPoint}/User`;
  }

  resetPassword(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/ResetPassword`, data);
  }

  ChangePassword(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/ChangePassword`, data);
  }

  ForgotPassword(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/ForgotPassword`, data);
  }

  UpdatePassword(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/UpdatePassword`, data);
  }

  updateScrollTop(data: any) {
    this.scrollTop.next(data);
  }

  updateUserConsent(data: UserConsent): Observable<any> {
    return this.http.post(`${this.baseUrl}/RegisterConsent`, data);
  }

}
