import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { MenuItem, PrimeNGConfig } from "primeng/api";
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';

import { Category, Categories } from "@core/interface";
import { StoreService, MainDataService, AuthTokenService, AppDataService } from "@core/services";
import {isPlatformBrowser} from "@angular/common";
import {DomUtil} from "leaflet";
import remove = DomUtil.remove;


@Component({
  selector: 'app-mtn-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})

export class SideMenuComponent implements OnInit, AfterViewInit {
  isShowAll: boolean = environment.isStoreCloud
  display: boolean = false;
  categories: Array<Category> = [];
  mobileCategories: any = [];
  mobileCategoriesStack: any = [];
  lang: string;
  loggedIn = false;
  items: MenuItem[] = [];
  authToken: any = '';
  subCategories: any = [];
  subCategories2: any = [];
  subCategories3: any = [];
  subCatNames: any = ['', '', ''];
  tenantId: string = '1';
  screenWidth: any;

  showSubCategory: boolean = false;
  subMenuCategories: any = [];

  isShowAllMerchants: boolean = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: StoreService, private router: Router,
    private mainDataService: MainDataService,
    private authTokenService: AuthTokenService,
    private cookieService: CookieService,
    private appDataService: AppDataService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.tenantId = localStorage.getItem('tenantId') ?? '1';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
      this.screenWidth = event.target.innerWidth;
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    // this.getCategories();

    const allMerchants = this.appDataService.layoutTemplate.find((t: any) => t.type === 'all_merchants')
    this.isShowAllMerchants = allMerchants ? allMerchants.isActive : false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.screenWidth = window.innerWidth;
      }
      this.cd.markForCheck();
      this.cd.detectChanges();
      this.authTokenService.authTokenData.subscribe(message => this.authToken = message);
      if (!this.authToken) {
        this.authToken = this.cookieService.get('authToken');
      }
      if (this.authToken)
        this.loggedIn = true;
      else
        this.loggedIn = false;

      this.store.subscription('categories')
        .subscribe({
          next: (res: any) => {
            this.categories = this.sortByOrder(res);
            // Implementation changes as per Omer Raffique 23/5/2024
            // hidden category with products count in any level should be shown
            for (let cat of this.categories) {

              cat['path'] = cat.categoryName;
              cat['catIds'] = cat.id;
              cat['parentChildCount'] = cat.totalProductCount;
              this.fetchCategoriesCount(this.sortByOrder(cat.categories), cat);
            }


          },
          error: (err: any) => {
            console.error(err);
          }
        });
    }, 1);
  }

  sortByOrder(arr :any){
    return arr.sort((a: { order: number; }, b: { order: number; }) => a.order - b.order);
  }
  fetchCategoriesCount(category: any, cat: any) {

    if (category.length == 0) {
      return;
    }

    for (const item of category) {
      item['path'] = cat.path + '//' + item.categoryName;
      item['catIds'] = cat.catIds + '//' + item.id;
      item['parentChildCount']=item.totalProductCount;
      this.fetchCategoriesCount(item.categories, item);
      cat['parentChildCount']+=item['parentChildCount'];
      if(cat.hide && cat.parentChildCount>0){
        cat.hide=false;
      }
    }

  }
  fetchCategories(category: any, cat: any) {

    if (category.length == 0) {
      return;
    }

    for (const item of category) {
      item['path'] = cat.path + '//' + item.categoryName;
      item['catIds'] = cat.catIds + '//' + item.id;

      this.fetchCategories(item.categories, item);
    }

  }


  openMenu() {
    this.display = true;
  }

  closeMenu(): void {
    this.display = false;
    this.subCategories = [];
    this.subCategories2 = [];
    this.subCategories3 = [];
    this.mobileCategories = this.categories;
    this.mobileCategoriesStack = [];
    this.showSubCategory = false
  }


  logOut(): void {

    this.authToken = null;
    this.loggedIn = false;
    sessionStorage.clear();


    this.authTokenService.authTokenSet("");

    this.cookieService.delete('authToken', '/');
    this.mainDataService.setCartLenghtData(null);
    this.mainDataService.setUserData(null);

    this.store.set('profile', '');
    this.store.set('cartProducts', '');
    localStorage.setItem('sessionId', '');
    localStorage.setItem('addedProducts', '');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('auth_enc');
    this.router.navigate(['/']);
  }
  removeHidedCategories(category : any){
    if(category.length){
      return;
    }
    for(let cat of category){
      const filteredCategores= cat.categories.filter((item : any) => !item.hide)
      if(filteredCategores.length){
        this.removeHidedCategories(cat.categories);
      }else{
        cat.categories = [];
      }
    }
  }
  getCategories(): void {
    const categories: Categories = this.sortByOrder(this.appDataService.categories);
    categories.records.forEach((item: any) => {
      const filteredCategores= item.categories.filter((item : any) => !item.hide)
      if(filteredCategores.length){
        this.removeHidedCategories(this.sortByOrder(item.categories));
      }else{
        item.categories = [];
      }

    })
    categories.records.forEach((cat: any) => {
      cat['path'] = '';
      cat['catIds'] = '';
      this.fetchCategories(this.sortByOrder(cat.categories), cat);
    });
    categories.records.forEach((category: any) => {
      if (category.categories.length > 0) {
        const subCategories: any = [];
        this.sortByOrder(category.categories).forEach((subCat: any) => {
          subCategories.push({ label: subCat.categoryName });
        });

        this.items.push({
          label: category.categoryName,
          items: subCategories
        });
      } else {
        this.items.push({
          label: category.categoryName
        });
      }
    });
    this.mobileCategories = categories.records;
  }

  assignSubCategories(subCat: any, catName: string) {
    this.subCategories = subCat;
    this.subCategories2 = [];
    this.subCatNames[0] = catName;
  }

  assignSubCategories2(subCat: any, catName: string) {
    this.subCategories2 = subCat;
    this.subCategories3 = [];
    this.subCatNames[1] = catName;
  }

  assignSubCategories3(subCat: any, catName: string) {
    this.subCategories3 = subCat;
    this.subCatNames[2] = catName;
  }

  selectMobileCategories(category: any) {
    this.mobileCategories = category.categories;
    this.mobileCategoriesStack.push(category);
  }

  async setPreviousMobileCategories() {
    this.mobileCategories = [];
    await this.mobileCategoriesStack.pop();
    if (this.mobileCategoriesStack.length > 0) {
      this.mobileCategories = this.mobileCategoriesStack[this.mobileCategoriesStack.length - 1].categories;
    } else {
      this.mobileCategories = this.categories;
    }
  }
}
