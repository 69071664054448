import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private permissions: string[] = [];
  private tagFeatureFlag: string[] = [];

  setPermissions(permissions: string[]): void {
    this.permissions = permissions;
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission);
  }


  setTagFeature(permissions: string[]): void {
    this.tagFeatureFlag = permissions;
  }
  getTagFeature(permission: string): boolean {
    return this.tagFeatureFlag.includes(permission);
  }
}
