<p-dialog class="flash-sale" [(visible)]="displayModal" [breakpoints]="{ '960px': '75vw', '768px': '90vw' }"
          [showHeader]="false"
          (onHide)="onCancel()"
          [resizable]="false" [closable]="false" [modal]="true">
  <ng-template pTemplate="content">
    <div class="flash-sale__content-section">
      <div class="flash-sale__content-section__images">
      <img [src]="getImagesURl(data.imageUrl)" alt="No Image">
      </div>
<!--      <div class="flash-sale__content-section__title">-->
<!--        “Up to 50% off ON “Men’s shorts-->
<!--      </div>-->
      <div class="d-flex flash-sale__content-section__timer-section" *ngIf="data.isFlashSaleTimer">
        <div class="flash-sale__content-section__timer-section__timer">
        <div class="flash-sale__content-section__timer-section__timer__time">
            {{hrs<10?'0':''}}{{hrs}}
          </div>
        <div class="flash-sale__content-section__timer-section__timer__label">
          {{"flashSaleModal.hours"| translate}}
        </div>
        </div>
        <div class="flash-sale__content-section__timer-section__timer">
          <div class="flash-sale__content-section__timer-section__timer__time">
            {{minutes<10?'0':''}}{{minutes}}
          </div>
          <div class="flash-sale__content-section__timer-section__timer__label">
            {{"flashSaleModal.min"| translate}}
          </div>
        </div>
        <div class="flash-sale__content-section__timer-section__timer">
          <div class="flash-sale__content-section__timer-section__timer__time">
            {{seconds<10?'0':''}}{{seconds}}
          </div>
          <div class="flash-sale__content-section__timer-section__timer__label">
            {{"flashSaleModal.sec"| translate}}
          </div>
        </div>

      </div>


    </div>

  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flash-sale__button-section">
      <div class="flash-sale__button-section__visit-button" (click)="onVisit()">
        {{"flashSaleModal.visit"| translate}}
      </div>
      <div class="flash-sale__button-section__close-button" (click)="onCancel()">
       {{"flashSaleModal.close"| translate}}
      </div>
    </div>

  </ng-template>
</p-dialog>
