import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetAllAboutUsRecords, GetAllContactUs, ResponseModel } from '@core/interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${environment.apiEndPoint}`;
  }


  getShopContactUs(): Observable<ResponseModel<GetAllContactUs>> {
    return this.http.get<ResponseModel<GetAllContactUs>>(`${this.baseUrl}/Tenant/ShopContactUs/GetAllShopContactUs`);
  }
  getShopAboutUs(): Observable<ResponseModel<GetAllAboutUsRecords>> {
    return this.http.get<ResponseModel<GetAllAboutUsRecords>>(`${this.baseUrl}/Tenant/ShopPages/GetAllShopPages`);
  }
  submitContactForm(params: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
debugger
    return this.http.post(
      `${environment.apiEndPoint}/Tenant/ContactForm/SubmitContact`,
      params.toString(),
      { headers: headers }
    );
  }
}
