<section class="section-block row">
  <div class="flex justify-content-center col-1 border-right desktop-layout">
    <h2 class="title-name my-0">{{ translated(title) | translate }}</h2>
    <button
      (click)="goToCategories()"
      *ngIf="categoryID !== 0"
      [label]="'buttons.viewAll' | translate"
      class="p-button-outlined view-all-btn mt-3"
      pButton
      type="button"
    >
      <img alt="No Image" class="arw" src="assets/images/payment-icons/a-r.svg"/>
    </button>
  </div>
  <div class="mt-3 col-11 mobile-show">
    <ng-container *ngIf="fetchStatus === 'completed'">
      <ng-content></ng-content>
    </ng-container>
    <span *ngIf="fetchStatus === 'pending'" class="loader"></span>

  </div>
</section>
