
<div class="not-found custom-css m-top" >
  <div class="container d-flex justify-content-around" >
      <div class="flex-column">

        <div class="flex-row text-wrapper-5 text-center">Oops....</div>
        <div class="flex-row text-wrapper-6 text-center">Page not found</div>
        <div class="flex-row">
          <p class="this-page-doesn-t">
            This Page doesn't exist or was removed!<br/>We suggest you&nbsp;&nbsp;back to home.
          </p>
        </div>
        <div class="flex-row">
          <button

            routerLink="/"
            [label]="'notFound.goBack' | translate"
            class="p-field p-col-12 my-2 width-100 font-size-14 second-btn"
            pButton
            type="button"
          ></button>
        </div>

      </div>

      <div class="flex-column">
        <img class="img not-found-img" src="https://c.animaapp.com/RLaOyNW3/img/frame.svg" alt="Page not found image"
            >
      </div>
  </div>
</div>
