import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable, of, Subject, tap} from "rxjs";
import {v4 as uuidv4} from "uuid";
import {castToHttpParams} from "../utilities";
import {MainDataService} from "@core/services/main-data.service";
import {LoaderService} from "@core/services/loader.service";

@Injectable({
  providedIn: 'root'
})
export class CartService {

  baseUrl: string;
  productChangeDetection = new Subject<any>();

  constructor(
    private http: HttpClient,
    private mainDataService: MainDataService,
    private loaderService: LoaderService
  ) {
    this.baseUrl = `${environment.apiEndPoint}/Product/Cart`;
  }

  getCart(data: any): Observable<object> {
    let url = `${this.baseUrl}/GetAllCart`;
    if (data.sessionId != '') {
      url += '?SessionId=' + data.sessionId;
    }
    if (data.applyTo) {
      url += '&applyTo=' + data.applyTo;
    }
    return this.http.get(url).pipe(
      map((res: any) => {
        if (res.data.records) {
          if (res.data.records.length) {
            this.mainDataService._cartItemshDataAfterLoginIn.next(res.data.records[0].cartDetails)
            if (res.data.records[0].cartDetails.length) {
              localStorage.setItem('cartId', res.data.records[0].cartDetails[0].cartId)
            } else if (res.data.records[0].cartDetailsDPay && res.data.records[0].cartDetailsDPay.length) {
              localStorage.setItem('cartId', res.data.records[0].cartDetailsDPay[0].cartId)
            }
            this.mainDataService.setCartLenghtData(res.data.records[0].cartDetails.length);
            this.mainDataService.setCartItemsData(res.data.records[0].cartDetails);
          }
         }
        return res;
      })
    );
  }

  getCartCount(data: any): Observable<object> {
    let url = `${this.baseUrl}/GetAllCartCount`;
    if (data.sessionId != '') {
      url += '?SessionId=' + data.sessionId;
    }
    if (data.applyTo) {
      url += '&applyTo=' + data.applyTo;
    }
    return this.http.get(url);
  }

  addToCart(data: any): Observable<object> {
    if (data.channelId == 2) {
      data.priceId = 0;
      data.specsProductId = 0;
    }
    return this.http.post(`${this.baseUrl}/CreateCart`, data);
  }

  emptyCart(cartId: any): Observable<object> {
    return this.http.get(`${this.baseUrl}/DeleteCart/${cartId}`);
  }

  removeItemFromCart(productId: number): Observable<object> {
    return this.http.get(`${this.baseUrl}/cart/remove-item/${productId}`);
  }

  updateCart(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/UpdateCart`, data);
  }

  productChangeDetect(product: any) {
    this.productChangeDetection.next(product);
  }

  getShipmentMethodByTenantId() {
    return this.http.get(`${environment.apiEndPoint}/Shipment/ShipmentMethod/GetShipmentMethodByTenantId`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public getOwnShipmentOptions(filter: any) {
    return this.http.get(`${environment.apiEndPoint}/Shipment/DeliveryOptions/getall?ApplyTo=1`, {params: castToHttpParams(filter)}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public getReterviedShipmentOptions(filter: any) {
    return this.http.get(`${environment.apiEndPoint}/Shipment/DeliveryOptions/getall?ApplyTo=2`, {params: castToHttpParams(filter)}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAllCart(data: any): Observable<any> {
    this.loaderService.show()
    return this.getCart(data).pipe(
      tap(() => this.loaderService.hide()),
      map((res: any) => {
        let cartListCount = 0;
        let cartListData = [];
        if (res.data?.records?.length) {
          if (res.data.records[0].cartDetails.length) {
            cartListCount = res.data.records[0].cartDetails.length;
            cartListData = res.data.records[0].cartDetails;
          }
          this.mainDataService.setCartLenghtData(cartListCount);
          this.mainDataService.setCartItemsData(cartListData);
        } else {
          this.mainDataService.setCartLenghtData(0);
          this.mainDataService.setCartItemsData([]);
        }
        return cartListData;
      }),
      catchError(error => {
        this.loaderService.hide();
        return of([]); // Return an empty array or handle accordingly
      })
    );

  }

}

export class GuidGenerator {
  static newGuid() {
    return uuidv4()
  }
}
