export const environment = {
  production: true,
  apiEndPoint: 'https://market.momo.africa:1010',//'https://momouat.ug:1010',
  featureProductsLimit: 100,
  platform: 'storecloud',
  isStoreCloud: false,
  isMarketPlace: true,
  testing: false,
  excludingTenants: [0],
  defaultTenant: '1',
  referer:1,
  merchantURL: 'https://market.momo.africa/sell',
  maintenanceMode: false,
  marketPlaceHostName: "market.momo.africa"
};
