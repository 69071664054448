<ng-container>
  <app-mtn-tenant-configuration *ngIf="
    showTenantSelection.includes(title) &&
      (tenantId === null || tenantId === '');
    else showLandingPage
  "></app-mtn-tenant-configuration>

  <ng-template #showLandingPage>
    <ng-container *ngIf="!isShowMaintenance()">
      <section #scroll *ngIf="initializeApp" class="container mw-100 px-0">
        <app-mtn-header *ngIf="showHeader"></app-mtn-header>
        <div [ngClass]="{'page-navbar-active': sliderConfig.isNavbarDataActive, 'page-navbar-inactive': !sliderConfig.isNavbarDataActive}"
             class="page">
          <p-toast [autoZIndex]="true" position="top-right" sticky="true"></p-toast>
          <div id="zoom-container">
            <div class="mobile-bg-border">
              <router-outlet></router-outlet>
            </div>
          </div>

          <app-mtn-footer (scrollToTop)="scrollToTop()" [footerDetails]="footerDetails"
                          [toHide]="isSpecificRoute()"></app-mtn-footer>
          <app-mobile-navbar *ngIf="!desktopView"></app-mobile-navbar>
        </div>
      </section>
      <ng-container
        *ngIf="!initializeApp && !(showTenantSelection.includes(title) && (tenantId === null || tenantId === ''))">
        <app-mtn-loader></app-mtn-loader>
      </ng-container>
    </ng-container>
    <app-maintenance *ngIf="isShowMaintenance()"></app-maintenance>
  </ng-template>

</ng-container>

