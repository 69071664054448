<p-dialog [(visible)]="displayModal" (onHide)="onHide()"
          [breakpoints]="{ '960px': '75vw', '640px': '90vw' }" [dismissableMask]="true" [draggable]="false" [showHeader]="true"
          [modal]="true" [resizable]="false" class="share-success-modal">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2" *ngIf="displayModal">
      <img alt="No Image" src="assets/icons/success-icon.svg">
      <span class="share-success-modal__title font-bold white-space-nowrap">{{titleMessage}}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="share-success-modal__body-container" *ngIf="displayModal && bodyMessage">
      <div class="d-flex align-items-center share-success-modal__body-container__primary-text">
        <div class="d-inline-flex">
          <p>{{bodyMessage}}</p>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
