import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-mtn-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagenotfoundComponent {

}
