<ng-container class="template-one">
  <section *ngIf="mainBanner.length>0" class="template-one__slider">
    <div class="d-flex justify-content-center">
      <app-mtn-main-slider [sliders]="mainBanner"></app-mtn-main-slider>
    </div>
  </section>

  <section *ngIf="rowOnePromotions.length>0" class="template-one__promotion">
    <div class=" d-flex justify-content-around mobile-second-banner">

      <p-carousel *ngIf="screenWidth<=767" [responsiveOptions]="rowOnePromotionResponsiveConfig"
                  [value]="rowOnePromotions" [circular]="false"  [autoplayInterval]="0" [showIndicators]="false" [showNavigators]="false">
        <ng-template let-banner pTemplate="item">
          <app-promotion-banner [banner]="banner" class="w-100 promotion-banner-small"/>
        </ng-template>
      </p-carousel>
      <ng-container *ngIf="screenWidth>767">
        <div class="template-one__promotion__promotion-content d-inline-flex" *ngFor="let banner of rowOnePromotions">
          <app-promotion-banner [banner]="banner" class="w-100 promotion-banner-small" />
        </div>
      </ng-container>

    </div>
  </section>
<!--  commented code-->
<!--*ngIf="rowTwoPromotions.length>0 || firstFeaturedProducts.data.length>0"-->
  <section  class="template-one__feature" [ngStyle]="{minHeight: (isMobileLayout && isMobileView) ? '600px' : '1000px'}">
    <div class="d-flex justify-content-space-between">
      <div *ngIf="screenWidth>1200 && rowTwoPromotions.length>0"
           class="template-one__feature__promotion-vertical d-inline-flex"
           [ngStyle]="{minHeight: (isMobileLayout && isMobileView) ? '600px' : '1000px'}">
        <app-promotion-vertical [banners]="rowTwoPromotions"/>
      </div>
      <div *ngIf="firstFeaturedProducts.isActive" [ngClass]="{'template-one__feature__feature-products-max':rowTwoPromotions.length==0}"
           class="template-one__feature__feature-products d-inline-flex pl-3"
      >
        <app-feature-products [featureId]="firstFeaturedProducts.id"
                              [isLeftVerticalBanner]="rowTwoPromotions.length==0 ? true : false" [products]="firstFeaturedProducts.data"
                              [showLoader]="firstFeaturedProducts.showLoader" [title]="firstFeaturedProducts.title"/>
      </div>
    </div>
  </section>

<!--  Best seller mobile banner-->
  <section *ngIf="bestSellerMobileBanner?.isActive" class="template-one__promotion-large">
    <div class=" d-flex justify-content-space-between">
      <div class="template-one__promotion-large__promotion-content d-inline-flex">
        <app-promotion-banner [banner]="bestSellerMobileBanner" class="w-100"/>
      </div>
    </div>
  </section>

  <section *ngIf="rowThreePromotions.length>0" class="template-one__promotion-medium">
    <div class=" d-flex justify-content-around">
      <p-carousel *ngIf="screenWidth<=767" [responsiveOptions]="rowThreePromotionResponsiveConfig"
                  [value]="rowThreePromotions" [circular]="false"  [autoplayInterval]="0" [showIndicators]="false" [showNavigators]="false">
        <ng-template let-banner pTemplate="item">
          <app-promotion-banner [banner]="banner" class="w-100 promotion-banner-small"/>
        </ng-template>
      </p-carousel>
      <ng-container *ngIf="screenWidth>767">
        <div *ngFor="let banner of rowThreePromotions"
             class="template-one__promotion-medium__promotion-content d-inline-flex">
          <app-promotion-banner [banner]="banner" class="w-100"/>
        </div>
      </ng-container>

    </div>
  </section>
  <!--  commented code-->
  <!--   *ngIf="rowFourPromotions.length>0 || secondFeaturedProducts.data.length>0"-->

  <section class="template-one__feature-2">
    <div class="d-flex justify-content-space-between">
      <div [ngClass]="{'template-one__feature-2__feature-products-max':rowFourPromotions.length==0}"
           class="template-one__feature-2__feature-products d-inline-flex pr-3">
<!--        *ngIf="secondFeaturedProducts.data.length>0"-->
        <app-feature-products *ngIf="secondFeaturedProducts.isActive"
                              [featureId]="secondFeaturedProducts.id" [isRightVerticalBanner]="rowFourPromotions.length==0 ? true : false"
                              [products]="secondFeaturedProducts.data" [showLoader]="firstFeaturedProducts.showLoader"
                              [title]="secondFeaturedProducts.title"/>
      </div>
      <div *ngIf="screenWidth>1200 && rowFourPromotions.length>0"
           class="template-one__feature-2__promotion-vertical d-inline-flex">
        <app-promotion-vertical [banners]="rowFourPromotions"/>
      </div>
    </div>
  </section>

  <!--  Best offer mobile banner-->
  <section *ngIf="bestOfferMobileBanner?.isActive" class="template-one__promotion-large">
    <div class=" d-flex justify-content-space-between">
      <div class="template-one__promotion-large__promotion-content d-inline-flex">
        <app-promotion-banner [banner]="bestOfferMobileBanner" class="w-100"/>
      </div>
    </div>
  </section>

  <section *ngIf="rowFivePromotions" class="template-one__promotion-large">
    <div class=" d-flex justify-content-space-between">
      <div class="template-one__promotion-large__promotion-content d-inline-flex">
        <app-promotion-banner [banner]="rowFivePromotions" class="w-100"/>
      </div>
    </div>
  </section>
<!--*ngIf="thirdFeaturedProducts.data.length>0"-->
  <section  class="template-one__feature-single" *ngIf="thirdFeaturedProducts.isActive">
    <app-feature-products [featureId]="thirdFeaturedProducts.id" [fullRowProducts]="true"
                          [isNewArrival]="true" [products]="thirdFeaturedProducts.data"
                          [showLoader]="firstFeaturedProducts.showLoader"
                          [title]="thirdFeaturedProducts.title"/>
  </section>
</ng-container>
<ng-container>
  <app-flash-sale-modal
    [data]="flashSaleData"
    (submit)="routeToCTA()"
    (cancel)="onFlashCancel()"
    [displayModal]="displayFlashSaleModal"

  >
  </app-flash-sale-modal>
</ng-container>
