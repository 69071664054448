import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  shipmentCost: number = 0;
  actualShipmentFee : number = 0;
  baseUrl: string;
  latitude: string = "";
  longitude: string = "";
  shipmentLoaded: boolean | undefined;
  currentShipment: any = null;
  currentOrderTotal: number = 0;
  updateShipMentUrl: string = '';

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${environment.apiEndPoint}/Shipment/YellowBirdShipment`;
    this.updateShipMentUrl = `${environment.apiEndPoint}/Shipment/PAPsShipment`;
  }


  createDeliveryRequest(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/DeliveryRequest`, data);
  }

  GetDeliveryPrice(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/PriceEstimation`, data);
  }


  updateShipmentStatus(orderId: any): any {
    return this.http.get<any>(`${this.updateShipMentUrl}/UpdateShipmentStatus?orderId=${orderId}`)
  }

}
