<div *ngIf="cookieModalDetails">

  <div class="modal-wrapper modal-body d-flex flex-column justify-content-center cookie-popup">
    <div class="cookie-popup__logo">
      <img *ngIf="!scConfig" alt="No Image" src="assets/icons/momo-marketplace-footer-logo.png" />
      <img class="logo-red" *ngIf="scConfig" alt="No Image" src="assets/icons/momo-marketplace-footer-logo-red.png" />
    </div>
    <div class="cookie-popup__header"><p>{{cookieModalDetails.header}}</p></div>
    <div class="cookie-popup__message"><p>{{cookieModalDetails.message}}</p></div>

    <div class="d-flex justify-content-center mt-4">
      <button
        (click)="proceed()"
        class="cookie-popup__buttons cookie-popup__buttons__accept-btn"
        >
        I accept
      </button>
      <button
        (click)="cancel()"
        class="cookie-popup__buttons cookie-popup__buttons__reject-btn"
        >
        I do not accept
      </button>
    </div>
  </div>
</div>
