<a
  class="category-card shadow-1 cursor-pointer no-underline"
  [ngStyle]="{
    width: showAllMobile === true ? '105px' : ''
  }"
  (click)="categoriesClick(category.id)"
>
  <img
    [alt]="category.categoryName"
    [src]="getImage(category.image)"
    (error)="errorHandler($event)"
  />
  <div class="title mt-3" title="{{ category.categoryName }}">
    {{ category.categoryName }}
  </div>
  <div class="total-items mt-2">
    {{ category.totalProductCount }} {{ "categoryCard.items" | translate }}
  </div>
</a>
