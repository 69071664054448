import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModalConfig, UserConsent} from '@core/interface';
import {environment} from "@environments/environment";
import {UserService} from "@core/services";
import {UserConsentType} from "@core/enums/user";

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss']
})
export class CookieModalComponent {
  @Input() cookieModalDetails: ModalConfig;
  scConfig: boolean = false
  @Output() submit = new EventEmitter<boolean>();

  constructor(public bsModalRef: BsModalRef,
              private userService: UserService) {
    this.scConfig = environment.isStoreCloud;
  }

  proceed(): void {
    localStorage.setItem('save_cookie', 'true');
    const data: UserConsent = {
      consentType: UserConsentType.Cookie,
      sessionId: localStorage.getItem('consumer-consent-sessionId') || '',
      consent: true
    }
    this.userService.updateUserConsent(data).subscribe({
      next: (res: any) => {
        console.log('res', res)
      }
    })
    this.bsModalRef.hide();
    this.submit.emit(true);
  }

  cancel(): void {
    localStorage.setItem('save_cookie', 'false')
    const data: UserConsent = {
      consentType: UserConsentType.Cookie,
      sessionId: localStorage.getItem('consumer-consent-sessionId') || '',
      consent: false
    }
    this.userService.updateUserConsent(data).subscribe({
      next: (res: any) => {
        console.log('res', res)
      }
    })
    this.bsModalRef.hide();
    this.submit.emit(false);

  }
}
