import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, map, Observable } from "rxjs";

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainDataService {
  baseUrl: string;
  browserLanguge: any;
  private _cartItemshData = new BehaviorSubject<any>([]);
  private _cartLengthData = new BehaviorSubject<any>(null);
  private _userData = new BehaviorSubject<any>(null);
  private _bannerData = new BehaviorSubject<any>({});
  public _cartItemshDataAfterLoginIn = new BehaviorSubject<any>([]);
  cartItemshDataAfterLoginIn = this._cartItemshDataAfterLoginIn.asObservable();

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${environment.apiEndPoint}`;


  }

  getAllCategories(): Observable<object> {
    return this.http.get(`${this.baseUrl}/Product/Category/GetAllCategory?PageSize=1000&IsActive=${true}`);
  }

  getShowRoomConfiguration(): Observable<any> {
    return this.http.get(`${this.baseUrl}/Tenant/ShowRoomConfiguration/GetTenantShowRoomConfiguration`);
  }

  getLayoutTemplate(isMobile = false): Observable<any> {
    return this.http.get(`${this.baseUrl}/Tenant/LayoutTemplate/GetSectionsByTenant/${isMobile}`).pipe(
      map((data: any) => {

        return data;
      })
    );;
  }

  getInitialData(): Observable<object> {
    return this.http.get(`${this.baseUrl}/Tenant/ThemeKeyConfiguration/GetInitialData`);
  }

  getAllAppConfigurations(referer: any): Observable<object> {
    return this.http.get(`${this.baseUrl}/Tenant/LandingPage/LandingPageConfiguration?Referer=${referer}&IgnorePagination=true`);
  }
  getAllGoogleTagConfigurations(): Observable<object> {
    return this.http.get(`${this.baseUrl}/Tenant/Configuration/GetGoogleTagConfiguration`);
  }

  getConfigurationData(): Observable<object> {
    return this.http.get(`${this.baseUrl}/Tenant/Configuration/GetAllConfiguration`);
  }
getGoogleAnalyticsTrackingId():Observable<any>{
  return this.http.get(`${this.baseUrl}/Tenant/Configuration/GetGoogleConfiguration?Referer=${environment.referer}`);
}
  getAllCountryTenants(): Observable<object> {
    const excludingTenants: any = environment.excludingTenants || [];
    return this.http.get(`${this.baseUrl}/Auth/CountryTenant/GetAllCountryTenants?Referer=${environment.referer}`).pipe(map((res: any) => {

      res.data.records = res?.data?.records?.filter((x: any) => !excludingTenants.includes(x.id))
      return res;
    }));
  }

  setCartItemsData(data: any) {
    this._cartItemshData.next(data);
  }

  getCartItemsData(): any {
    return this._cartItemshData;
  }

  setCartLenghtData(data: any) {
    this._cartLengthData.next(data);
  }

  getCartLengthData(): any {
    return this._cartLengthData;
  }

  setUserData(data: any) {
    this._userData.next(data);
  }

  getUserData(): any {
    return this._userData;
  }

  setBannerData(data: any) {
    this._bannerData.next(data);
  }

  getBannerData(): any {
    return this._bannerData;
  }


}
