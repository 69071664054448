import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {JwtHelperService} from "@auth0/angular-jwt";
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';

import {environment} from 'src/environments/environment';
import {ResponseModel, RefreshTokenViewModel} from "../interface";

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  isRefreshTokenCalled: boolean = false;
  helper = new JwtHelperService();
  baseUrl: string;
  userController: string;
  otpController: string;
  private authToken = new BehaviorSubject<any>(null);
  authTokenData = this.authToken.asObservable();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService) {
    this.baseUrl = `${environment.apiEndPoint}/Auth`;
    this.userController = '/User';
    this.otpController = '/OtpUser';
  }

  authTokenSet(message: string) {

    this.authToken.next(message);
  }


  refreshToken(data: RefreshTokenViewModel): Observable<ResponseModel<RefreshTokenViewModel>> {
    return this.http.post(`${this.baseUrl}${this.userController}/RefreshToken`, data)
      .pipe(map(res => {
        return res;
      }));
  }

}
