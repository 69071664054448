import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output
} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {environment} from '@environments/environment';
import {StoreService, ProductService, PermissionService} from "@core/services";

@Component({
  selector: 'app-mtn-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  search: any = '';
  displayResult: boolean = false;
  searchResult: Array<any> = [];
  productId: number = 0;
  scConfig: boolean = false;
  isMobileTemplate:boolean=false;
  @Output() onResult: EventEmitter<boolean> = new EventEmitter();
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);

    if (!clickedInside) {
      this.showResultPopUp(false);
    }
  }
  constructor(
    private store: StoreService, private productService: ProductService,
    private messageService: MessageService, private cd: ChangeDetectorRef,private elementRef: ElementRef,
    private permissionService: PermissionService,
    private translate: TranslateService, private router: Router) {
    this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout');
    router.events
      .subscribe((event: any) => {
        if (event.navigationTrigger === 'popstate' || (event.navigationTrigger === 'imperative' && !event.url?.includes('search'))) {
          this.search = null;
        }
      });
    if (environment.isStoreCloud) {
      this.scConfig = true;
    } else {
      this.scConfig = false;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    /**/
  }

  ngAfterViewInit(): void {
    this.store.subscription('search')
      .subscribe(
        (res: string) => {
          this.search = res;
        }
      );
  }

  searchProduct(event: any, searchKay?: any): void {
    if (event?.target?.value) {
      this.closeDropDown();
      this.router.navigate(
        ['/search'],
        {queryParams: {q: event?.target?.value}}
      );
    }

  }

  showResultPopUp(show: boolean): void {
    this.displayResult = show;
  }

  NavigateToProduct(productId: any) {

    this.router.navigate(['product/'+ productId+'/'+('1')],{
      queryParams: {
        tenantId: localStorage.getItem("tenantId"),
        lang:localStorage.getItem("lang")
      },
      queryParamsHandling: 'merge',
    })
    this.showResultPopUp(false);

  }

  closeDropDown() {
    this.showResultPopUp(false);

  }

  searchChange(event: any) {
    const regex = /^[^a-zA-Z0-9]+/;


    this.search = event.replace(regex, '');
    this.cd.detectChanges();
    if (this.search === '') {
      this.search = null
    }


  }
  keyUpSearch(event:any){

    if (event.target.value && event.code != 'Enter') {

      this.productService.FilterWithSearchProductName(event.target.value).subscribe({
        next: (res: any) => {
          this.searchResult = res.data.products.records;
          this.showResultPopUp(true);
        },
        error: (err: any) => {
          this.messageService.add({ severity: 'error', summary: this.translate.instant('ErrorMessages.fetchError'), detail: err.message });
        },
        complete: () => { }
      });
    }
  }
}
