import {Component, Input, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Router} from "@angular/router";
import {Category} from '@core/interface';

@Component({
  selector: 'app-mtn-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss'],
})
export class CategoryCardComponent implements OnInit {
  @Input() category: Category = {} as Category;
  @Input() showAllMobile: boolean = false;
  baseUrl = environment.apiEndPoint;

  constructor(private router: Router) {


  }


  ngOnInit(): void {
    /**/
  }

  getImage(imageLink: any) {

    if (imageLink) {
      if (imageLink[0] === '/') imageLink = imageLink.substring(1);
      const sub = imageLink.substring(0, imageLink.indexOf('/'));
      if (sub.toLowerCase().includes('images')) {

        return `${this.baseUrl}/${imageLink}`;
      } else {

        return `${this.baseUrl}/Images/${imageLink}`;
      }
    } else {
      return '';
    }
  }

  errorHandler(event: any) {
    if (environment.isStoreCloud) {
      event.target.src = "assets/images/placeholder.png";

    } else {
      event.target.src = "assets/images/mtn-alt.png";

    }
  }
  categoriesClick(id:any){
    this.router.navigate(['category', id],{
      queryParams: {
        tenantId: localStorage.getItem("tenantId"),
        lang:localStorage.getItem("lang")
      },
      queryParamsHandling: 'merge',
    })
  }
}
