import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from '@core/services';

@Component({
  selector: 'app-mtn-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isLoading: any;

  constructor(private loaderService: LoaderService, private ref: ChangeDetectorRef,) {
    this.loaderService.isLoading.subscribe((res => {

      this.isLoading = res;
      this.ref.markForCheck();
      this.ref.detectChanges();
    }));

  }

  ngOnInit(): void {
    /**/
  }

}
