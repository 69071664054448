export interface CheckMobile {
    CountryId: string,
    UserName: string,
    UserRole?:RoleEnum;

}
export interface CheckOtp {
    CountryId: string,
    UserName: string,
    OTPCode: string
}
export interface RegisterUser {
    UserName: string,
    Password: string,
    OTPCode: string,
    Name: string,
    number: string,
    Email: string|null
}
export enum RoleEnum {
    merchant = 1,
    consumer = 2,
    admin = 3,
    sysAdmin = 4,
    tenantMaker = 5,
    tenantChecker = 6,
    marketplaceMaker = 7,
    operation = 8,
    shopAdmin = 9,
    CallCenter = 10,
    Guest = 11
  }
