import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {Promotion} from "@core/interface";
import UtilityFunctions from "@core/utilities/functions";
import {environment} from "@environments/environment";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-promotion-banner',
  templateUrl: './promotion-banner.component.html',
  styleUrls: ['./promotion-banner.component.scss']
})
export class PromotionBannerComponent {
  @Input() banner:Promotion
  @Input() promotionId:any;
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }
  routeToCTA(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      if(this.banner.promotionId){
        if(this.banner.ctaLink) {
          const cta = this.banner.ctaLink.replace(/promotions\//g, "promotion/")
          if(!this.banner.ctaLink.includes('http://') && !this.banner.ctaLink.includes('https://')){
            window.location.href = 'https://'+cta;
          } else {
            window.location.href = cta;
          }
        } else {
          let tempurl=' https://'+environment.marketPlaceHostName+'/promotion/'+this.banner.promotionId;
          window.location.href = tempurl;
        }
      }
      else{
        window.location.href = url;
      }

    }
  }
  getBannerImages(url: string) {
    return UtilityFunctions.verifyImageURL(url, environment.apiEndPoint);
  }
}
