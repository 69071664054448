export enum ShowRoomTypeEnum
{
  Banner = 1,
  Product = 2,
  Category = 3,
  Offer = 4,
  Brand = 5,
  SubCategory = 6,
  BrowsingHistory = 7,
  MainBanner = 8,
}
