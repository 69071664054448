import {ChangeDetectorRef, Component} from '@angular/core';
import {AuthTokenService, CommonService, MainDataService, PermissionService} from "@core/services";
import {CookieService} from "ngx-cookie-service";
import {ActivatedRoute, Router} from "@angular/router";

interface Menu {
  name: string,
  icon: string,
  filledIcon: string
}

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss']
})
export class MobileNavbarComponent {
  authToken: any = '';
  floatingMenu: Menu[];
  cartListCount: any = 0;
  isMobileTemplate: boolean = false;
  selectedMenu : Menu | null;
  constructor(private authTokenService: AuthTokenService,
              private cookieService: CookieService,
              protected router: Router,
              private mainDataService: MainDataService,
              private cd: ChangeDetectorRef,
              private commonService : CommonService,
              private permissionService: PermissionService) {
    this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout');
  }

  ngOnInit() {
    this.mainDataService.getCartLengthData().subscribe((data: any) => {

      this.cartListCount = data ?? 0;
      if (this.cartListCount > 0) {

        if (this.cartListCount > 9) {
          this.cartListCount = '9+';
        }
      }
      this.cd.markForCheck();
      this.cd.detectChanges();
    });
    this.floatingMenu = [
      {
        name: 'home',
        icon: 'assets/icons/mobile-home.svg',
        filledIcon: 'assets/icons/mobile-home-filled.svg'
      },
      {
        name: 'wishlist',
        icon: 'assets/icons/mobile-wishlist.svg',
        filledIcon: 'assets/icons/mobile-wishlist-filled.svg'
      },
      {
        name: 'categories',
        icon: 'assets/icons/mobile-categories.svg',
        filledIcon: 'assets/icons/mobile-categories-filled.svg'
      },
      {
        name: 'cart',
        icon: 'assets/icons/mobile-cart.svg',

        filledIcon: 'assets/icons/mobile-cart-flled.svg'
      },
      {
        name: 'profile',
        icon: 'assets/icons/mobile-profile.svg',
        filledIcon: 'assets/icons/mobile-profile-filled.svg'
      }
    ]
    this.selectedMenu = this.floatingMenu[0];
    this.commonService.isShowSearchBox.next(true);
    this.authTokenService.authTokenData.subscribe(
      (message) => (this.authToken = message)
    );
    if (!this.authToken) {
      this.authToken = this.cookieService.get('authToken');
    }
  }

  navigateFloating(menu: Menu) {
    const tempSelectedMenu: Menu | null = this.floatingMenu.find((m: Menu) => m.name === menu.name) ?? null

    if (menu.name === 'profile') {
      if (this.authToken) this.router.navigate(['/account'])
      else this.router.navigate(['/login'])
    } else if(menu.name === 'home') {
      this.router.navigate(['/'])
    } else if(menu.name === 'wishlist') {
      if (this.authToken) this.router.navigate(['/wishlist'])
      else this.router.navigate(['/login'])
    }
    else if(menu.name === 'cart') {
     this.router.navigate(['/cart'])
    }
    else if(menu.name === 'categories') {
      this.router.navigate(['/categories-list'])
    }
    if(tempSelectedMenu?.name === this.selectedMenu?.name){
      this.commonService.isShowSearchBox.next(true);
    }else{
      this.commonService.isShowSearchBox.next(false);

    }
    this.selectedMenu = tempSelectedMenu;
  }

  renderMenuFilledIcon(menu: string) {
    const tempSelectedMenu : Menu | null  = this.floatingMenu.find((m: Menu) => m.name === menu) ?? null
    if(tempSelectedMenu) {
      if(tempSelectedMenu.name === 'profile') {
        return this.router.url.includes('login') || this.router.url.includes('account');
      } else if(tempSelectedMenu.name === 'home') {
        return this.router.url === '/';
      }
      else if(tempSelectedMenu.name === 'wishlist') {
        return this.router.url.includes('/wishlist');
      }
      else if(tempSelectedMenu.name === 'cart') {
        return this.router.url === '/cart';
      }
      else if(tempSelectedMenu.name === 'categories') {
        return this.router.url === '/categories-list';
      }
    }
  }
}
