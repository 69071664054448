import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  baseUrl: string = environment.apiEndPoint;
  likeUnlike: any;

  constructor(private http: HttpClient) {

  }

  wishlistToggle({specsProductId, productId, channelId, flag}: any) {
    if (flag) {
      this.likeUnlike = 'DeleteCustomerWishList';
    } else {
      this.likeUnlike = 'CreateCustomerWishList';
    }

    if(channelId == 2) {
      specsProductId = 0;
    }

    return this.http.post(
      `${this.baseUrl}/Product/CustomerWishList/${this.likeUnlike}`,
      {
        specsProductId,
        productId,
        channelId
      }
    );
  }
}
