import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";

import {
  ResponseModel,
  CreateReviewViewModel,
  GetProductReviewRates,
  ProductRate,
  ReviewDetailResponse
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${environment.apiEndPoint}`; //api/front-shop`
  }

  getProductReviews(id: number, params: any): Observable<object> {
    return this.http.get(`${this.baseUrl}/Review/review/showReviews/${id}`, {params});
  }


  getProductRate(productsId: number[]): Observable<{ proReview: ProductRate[] | undefined; }> {


    return this.http.post<ResponseModel<ProductRate[]>>
    (`${this.baseUrl}/Review/Review/GetProductReviewRates`, productsId)
      .pipe(map(review => {


        return {proReview: review.data};
      }));
  }

  getProductReviewRate(productId: number): Observable<{ proReview: GetProductReviewRates | undefined; }> {


    return this.http.get<ResponseModel<GetProductReviewRates>>(`${this.baseUrl}/Review/Review/GetProductDetailReviewRates/${productId}`)
      .pipe(map(review => {


        return {proReview: review.data};
      }));
  }

  getProductReviewDetail(productId: number): Observable<{
    proReview: ResponseModel<ReviewDetailResponse[]> | undefined;
  }> {


    return this.http.get<ResponseModel<ReviewDetailResponse[]>>(`${this.baseUrl}/Review/ReviewDetail/GetAllReviewByProduct/${productId}`)
      .pipe(map(review => {


        return {proReview: review};
      }));
  }

  addProductReviews(model: CreateReviewViewModel): Observable<boolean> {

    return this.http.post<boolean>(`${this.baseUrl}/Review/review/CreateReview`, model);
  }
}
