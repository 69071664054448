import { HttpParams } from "@angular/common/http"

export function isInstance<T extends object>(value: string, type: T): type is T {
    return Object.values(type).includes(value)
  };
  export interface LooseObject {
    [key: string]: any
  }
  export function castToHttpParams(data: LooseObject): HttpParams{
    return Object.entries(data).reduce((params, [key, value]) => {
      if (value !== null) {
        return params.set(key, value);
      } else {
        return params;
      }
    }, new HttpParams());
  }
