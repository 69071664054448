export class ProductRate {
  public count: number | undefined;
  public rate: number | undefined;
  public productId: number | undefined;


}

export class GetProductReviewRates {
  public count: number | undefined;
  public rate: number | undefined;
  public rateOne: number | undefined;
  public rateTwo: number | undefined;
  public rateThree: number | undefined;
  public rateFour: number | undefined;
  public rateFive: number | undefined;

}

export class ReviewDetailResponse {
  public id: number | undefined;
  public describtion: string | undefined;
  public rate: number | undefined;
  public creationOn: Date | undefined;
  public customerName: string | undefined;
  public approved: boolean | null | undefined;

}

export class CreateReviewViewModel {
  public SpecProductIds: any[] | undefined;
  public ReviewType: number | undefined;
  public Rate: number | undefined;
  public Description: string | undefined;
  public OrderId: number | undefined;

}
