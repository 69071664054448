<p-dialog [(visible)]="displayModal" [style]="{ width: '30rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [closable]="false"
  [blockScroll]="true" [showHeader]="true" [draggable]="false" [modal]="true" class="notifyModal">
    <ng-template pTemplate="header">
      <div class="inline-flex align-items-center justify-content-center gap-2" *ngIf="displayModal">
        <span class="notifyModal__title font-bold white-space-nowrap">{{"notifyMeDetails.notifyMeVia" | translate}}</span>
      </div>
    </ng-template>
    <div class="notifyModal__body-container" *ngIf="displayModal">
      <div class="d-flex align-items-center">
        <div class="d-inline-flex notifyModal__body-container__checkbox-container">
          <div class="custom-checkbox">
            <p-checkbox class="notifyModal__body-container__checkbox-container__checkbox" [binary]="true" inputId="binary"  [(ngModel)]="isNumberChecked" (ngModelChange)="isChangeOption('number')"
                        [ngModelOptions]="{standalone: true}"></p-checkbox>
          </div>
        </div>
        <div class="d-inline-flex notifyModal__body-container__primary-text" style="width:90%">
          <label>{{"notifyMeDetails.byNumber" | translate}}</label>
        </div>
      </div>
      <div class="d-flex align-items-center mt-2">
        <div class="d-inline-flex w-100">
          <form [formGroup]="notifyForm" class="w-100"
                [ngStyle]="{opacity:!isNumberChecked? '0.5': '' }"
          >
            <ngx-intl-tel-input
              *ngIf="predefinedCountries.length > 0"
              [cssClass]="'custom contact-input-phone'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [maxLength]="phoneLength"
              [numberFormat]="PhoneNumberFormat.International"
              [phoneValidation]="false"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [searchCountryFlag]="false"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CustomCountryISO"
              [separateDialCode]="true"
              [onlyCountries]="predefinedCountries"
              customPlaceholder="{{ phoneNumberlabel}}"
              (countryChange)="countryChange($event)"
              formControlName="phone" name="phone"
            ></ngx-intl-tel-input>
          </form>
        </div>

      </div>
      <div class="d-flex align-items-center mt-4 mb-5" *ngIf="isEmailExist">
        <div class="d-inline-flex notifyModal__body-container__checkbox-container">
          <div class="custom-checkbox">
            <p-checkbox class="notifyModal__body-container__checkbox-container__checkbox" [binary]="true" inputId="binary"  [(ngModel)]="isEmailChecked" (ngModelChange)="isChangeOption('email')"
                        [ngModelOptions]="{standalone: true}"></p-checkbox>
          </div>
        </div>
        <div class="d-inline-flex notifyModal__body-container__primary-text" style="width:90%">
          <label>{{"notifyMeDetails.byEmail" | translate}}</label>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="d-flex" *ngIf="displayModal">
        <button (click)="onClick()" class="p-field p-col-12 mb-2  mt-3 width-100 notifyModal__action-btn notifyModal__action-btn__notify-btn"  pButton
                [disabled]="(isNumberChecked  && !numberValidator())  ||
               (!isNumberChecked && !isEmailChecked)"
                [label]="sumbitText"
                type="button">

        </button>
      </div>
      <div class="d-flex">
        <button (click)="onClose()" class="p-field p-col-12 mb-2  width-100 notifyModal__action-btn notifyModal__action-btn__close-btn"  pButton
                [label]="closeText"
                type="button">

        </button>
      </div>

    </ng-template>
</p-dialog>
