import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

function loadScript(apiKey: string) {
  return new Promise((resolve, reject) => {
    const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
    setTimeout(() => {
      resolve(true);
    }, 500)

  });
}

function shouldGetGoogleKey(mapKeyExpiry: any){
  const date1 = new Date(mapKeyExpiry);
  const date2 = new Date();

  const time1 = date1.getTime();
  const time2 = date2.getTime();

  const differenceInMinutes = (time2 - time1) / (1000 * 60);
  return differenceInMinutes >= 30;
}

function fetchGoogleKey(tenantId: any) {
  fetch(environment.apiEndPoint + '/Auth/TenantConfiguration/GetGoogleKey', {
    method: 'GET',
    headers: {
      'tenantId': tenantId && tenantId !== '' ? tenantId : environment.defaultTenant
    }
  })
    .then((res) => res.json())
    .then((resp) => {
        localStorage.setItem('mapKey', atob(resp.data.key));
        localStorage.setItem('mapKeyExpiry', (new Date).getTime().toString());
        fetchAnalyticsKey(tenantId)

      },
      (error) => {
        fetchAnalyticsKey(tenantId)
      });
}
function fetchAnalyticsKey(tenantId: any) {
  fetch(environment.apiEndPoint + `/Tenant/Configuration/GetGoogleConfiguration?Referer=${environment.referer}`, {
    method: 'GET',
    headers: {
      'tenantId': tenantId && tenantId !== '' ? tenantId : environment.defaultTenant
    }
  })
    .then((res) => res.json())
    .then((resp) => {
        localStorage.setItem('GATrackingId', resp?.data ? resp?.data[0]?.value : '');
        loadScript(localStorage.getItem('mapKey') || '').then(() => {
          platformBrowserDynamic().bootstrapModule(AppModule)
            .catch(err => console.error(err));
        });
      },
      (error) => {
        localStorage.setItem('GATrackingId', '');
        platformBrowserDynamic()
          .bootstrapModule(AppModule)
          .catch((err) => console.error(err));
      });
}

function bootstrap() {
  const tenantId = localStorage.getItem('tenantId');
  const mapKeyExpiry = localStorage.getItem('mapKeyExpiry');
  if(mapKeyExpiry && mapKeyExpiry !== '') {
    if(shouldGetGoogleKey(parseInt(mapKeyExpiry))) {
      fetchGoogleKey(tenantId);
    } else {
      const mapKey: any = localStorage.getItem('mapKey');
      loadScript(mapKey).then(() => {
        platformBrowserDynamic().bootstrapModule(AppModule)
          .catch(err => console.error(err));
      });
    }
  } else {
    fetchGoogleKey(tenantId)
  }

}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap, { passive: true });
}
