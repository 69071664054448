import {Injectable, Signal, WritableSignal, signal} from '@angular/core';
import {
  Categories,
  ConfigurationResponse,
  InitialData,
  Tenant,
  AllShowroomConf,
  CategoryRecords
} from "@core/interface";

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  private _initialData: InitialData;
  private _showRoomConfiguration: AllShowroomConf;
  private _layoutTemplate: any;
  private _shopSettingData: Categories;
  private _categories: Categories;
  private _tenants: Tenant;
  private _configuration: ConfigurationResponse;
  private dialogVisibilitySignal: WritableSignal<boolean> = signal(false);

  get dialogVisibility(): Signal<boolean> {
    return this.dialogVisibilitySignal.asReadonly();
  }

  showDialog() {
    this.dialogVisibilitySignal.set(true);
  }
  hideDialog() {
    this.dialogVisibilitySignal.set(false);
  }
  get initialData(): InitialData {
    return this._initialData
  }

  set initialData(data: InitialData) {
    this._initialData = data;
  }

  get configuration(): ConfigurationResponse {
    return this._configuration;
  }

  set configuration(data: ConfigurationResponse) {
    this._configuration = data;
  }

  get tenants(): Tenant {
    return this._tenants;
  }

  set tenants(data: Tenant) {
    this._tenants = data;
  }

  get categories(): Categories {
    return this._categories;
  }

  set categories(data: Categories) {
    const sortedCategories = this.sortCategories(data.records);
    data.records = sortedCategories
    this._categories = data;
  }

  get showRoomConfiguration(): any {
    return this._showRoomConfiguration;
  }

  set showRoomConfiguration(data: AllShowroomConf) {
    this._showRoomConfiguration = data;
  }

  get layoutTemplate(): any {
    return this._layoutTemplate;
  }

  set layoutTemplate(data: any) {
    this._layoutTemplate = data;
  }

  get shopSettingDsata(): any {
    return this._shopSettingData;
  }

  set shopSettingData(data: any) {
    this._shopSettingData = data;
  }

  clearAppData(){
    localStorage.removeItem('appData_expiry');
    localStorage.removeItem('appData_cache');
  }

  sortCategories(categories: CategoryRecords[]): CategoryRecords[] {
    categories.sort((a: any, b: any) => a.order - b.order);
    categories.forEach((category : any) => {
      if (category.categories) {
        category.categories = this.sortCategories(category.categories);
      }
    });

    return categories;
  }
}

