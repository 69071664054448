import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {MessageService} from 'primeng/api';
import {catchError, map, Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {v4 as uuidv4} from "uuid";

import {
  MainDataService,
  AuthTokenService,
  CartService,
  ProductService,
  StoreService
} from "./";

import {Product} from "../interface";

@Injectable({
  providedIn: 'root'
})
export class ProductLogicService {
  cartProductList: Array<Product>;
  favouritesProductList: Array<Product>;
  compareProductList: Array<Product>;
  lang: string;
  isAuthUser: any;
  sessionId: string = "";
  addedProducts = Array<Product>();

  constructor(
    private store: StoreService,
    private messageService: MessageService,
    private productService: ProductService,
    private cartService: CartService,
    private router: Router,
    private translate: TranslateService,
    private authTokenService: AuthTokenService,
    private mainDataService: MainDataService
  ) {
    this.cartProductList = [];
    this.favouritesProductList = [];
    this.compareProductList = [];
    this.lang = this.store.get('lang');
    this.authTokenService.authTokenData.subscribe(message => this.isAuthUser = message);
  }


  public modifyCart(product: any, type: string, newProducts?: any) {

    this.getStoreData();
    return this.cartModificationApi(product, type, newProducts)
  }

  public emptyCart(cartId: any): Observable<boolean> {
    this.cartService.emptyCart(cartId)
      .subscribe({
        next: (res: any) => {
          this.cartProductList = res.data.cartItems;
          // if (res.data.cartItems !== undefined && res.data.cartItems !== null && res.data.cartItems.length === 0) {
          //   localStorage.removeItem('sessionId');
          // }
          this.mainDataService.setCartLenghtData(0);
          this.mainDataService.setCartItemsData([]);
          this.setCartToStore(this.cartProductList, "", true);
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('ResponseMessages.cart'),
            detail: this.translate.instant('ResponseMessages.successfullyUpdatedFromCart')
          });
          return true;
        },
        error: (err: any) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('ErrorMessages.fetchError'),
            detail: err.message
          });
          return false;
        }
      });
    return of(false);
  }

  private getStoreData(): void {
    this.authTokenService.authTokenData.subscribe(message => this.isAuthUser = message);
    this.lang = this.store.get('lang');
    this.cartProductList = this.store.get('cartProducts');
    this.favouritesProductList = this.store.get('favouritesProducts');
    this.compareProductList = this.store.get('compareProducts');
  }


  private userCart(product: any, type: string, newProducts?: any): Observable<boolean> {
    return this.cartModificationApi(product, type, newProducts);
  }

  private guestCart(product: any, type: string, newProducts?: any): Observable<boolean> {
    return this.cartModificationApi(product, type, newProducts);
  }

  private cartModificationApi(product: any, type: string, newProducts?: any): Observable<boolean> {
    const sessionId = this.getSessionId();

    switch (type) {
      case 'add':
        return this.addToCart(product, sessionId, newProducts);

      case 'delete':
        return this.removeFromCart(product, newProducts);

      case 'update':
        return this.updateCart(product, sessionId, newProducts);

      default:
        return of(false);
    }
  }

  private getSessionId(): string {
    let sessionId: string = localStorage.getItem('sessionId') ?? '';

    if (!sessionId) {
      sessionId = GuidGenerator.newGuid();
      localStorage.setItem('sessionId', sessionId);
    }

    return sessionId;
  }

  private addToCart(product: any, sessionId: string, newProducts?: any): Observable<boolean> {
    product.sessionId = sessionId;

    return this.cartService.addToCart(product).pipe(
      map((res: any) => {
        if (res?.success) {
          this.handleCartModificationSuccess(res, product, newProducts);
          return true;
        } else {
          this.handleCartModificationError(res);
          return false;
        }
      }),
      catchError((err: any) => this.handleCartModificationError(err))
    );
  }

  private removeFromCart(product: any, newProducts?: any): Observable<boolean> {
    return this.cartService.removeItemFromCart(product).pipe(
      map((res: any) => {
        this.handleCartModificationSuccess(res, product, newProducts);
        return true;
      }),
      catchError((err: any) => this.handleCartModificationError(err))
    );
  }

  private updateCart(product: any, sessionId: string, newProducts?: any): Observable<boolean> {
    product.sessionId = sessionId;

    return this.cartService.updateCart(product).pipe(
      map((res: any) => {
        if (!res.success) {
          this.handleCartModificationError(res);
          return false;
        }

        this.handleCartModificationSuccess(res, product, newProducts);
        return true;
      }),
      catchError((err: any) => this.handleCartModificationError(err))
    );
  }

  private handleCartModificationSuccess(res: any, product: any, newProducts?: any): void {
    this.cartProductList = res.data.cartItems;
    this.setCartToStore(this.cartProductList, product);
    this.mainDataService.setCartLenghtData(res.data.cartItems.length);

    if (newProducts === 'buynow') {
      this.router.navigate(['/cart']);
    }

    this.messageService.add({
      severity: 'success',
      summary: this.translate.instant('ResponseMessages.cart'),
      detail: this.translate.instant('ResponseMessages.successfullyAddedToCart')
    });
  }

  private handleCartModificationError(err: any): Observable<boolean> {
    this.messageService.add({
      severity: 'error',
      summary: this.translate.instant('ErrorMessages.fetchError'),
      detail: err.message
    });
    return of(false);
  }


  private setCartToStore(cartList: Array<any>, product: any, removed?: boolean) {
    this.addedProducts = Array<any>();
    this.store.set('cartProducts', cartList);
    this.store.set('cartProductSuccess', product);
    this.addedProducts = cartList


    localStorage.setItem('addedProducts', JSON.stringify(this.addedProducts));

  }
}


class GuidGenerator {
  static newGuid() {
    return uuidv4()
  }
}
