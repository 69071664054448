import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Currency } from "@core/interface";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-feature-products',
  templateUrl: './feature-products.component.html',
  styleUrls: ['./feature-products.component.scss']
})
export class FeatureProductsComponent implements OnInit {
  @Input() title: string;
  @Input() products: any;
  screenWidth: number = window.innerWidth;
  isMobileView: boolean = this.screenWidth <= 786;

  @Input() featureId: any;
  @Input() fullRowProducts: boolean = false
  @Input() showLoader: boolean = true
  currency: Currency = {} as Currency;
  topNumber: number = 1000;
  translatedData: any;
  @Input() isNewArrival: boolean = false;
  @Input() isLeftVerticalBanner: boolean = false;
  @Input() isRightVerticalBanner: boolean = false;
  constructor(private router: Router,
    private translate: TranslateService) {
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    if (this.screenWidth <= 768) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

  }
  ngOnInit() {
    this.translate.get('landing').subscribe((res: any) => {
      this.translatedData = res;
    });

  }

  redirectFeaturedProducts() {
    this.router.navigate([
      `/category/${this.featureId}&${this.topNumber}&${this.translated(this.title)}`,
    ]);
  }

  translated(name: string) {
    let transData: any;
    if (!name) return ""
    name = name?.replace(/\s/g, '');

    this.translate.get('featureType').subscribe((data: any) => {
      transData = data;
    });

    return transData[name?.toLowerCase()] ? transData[name?.toLowerCase()] : name;
  }
  getTranslatedTitle(title: any) {
    if (title === '') {
      return '';
    }
    title = title?.replace(/\s/g, '');
    return this.translatedData[title]
  }
}
