import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {AuthTokenService} from '@core/services';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  token: any = '';

  constructor(
    private router: Router,
    private injector: Injector,
    private authTokenService: AuthTokenService, private cookieService: CookieService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let localToken = null;
    this.authTokenService.authTokenData.subscribe((message: any) => localToken = message);
    const cookieToken = this.cookieService.get('authToken');
    if (localToken) {
      this.token = localToken;
      return true;
    } else if (cookieToken && cookieToken !== '') {
      this.token = cookieToken;
      return true;
    }


    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
