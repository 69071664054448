import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {Meta} from "@angular/platform-browser";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {v4 as uuidv4} from "uuid";

import {ConfigurationKeys} from "@core/enums";
import {Categories, ConfigurationResponse, InitialData, TenantRecords} from "@core/interface";
import {
  MainDataService,
  LanguageService,
  AppDataService,
  AuthService,
  TenantService,
  AuthTokenService,
  CartService,
  StoreService, CommonService
} from "@core/services";
import {isPlatformBrowser} from "@angular/common";


class GuidGenerator {
  static newGuid() {
   return  uuidv4()
  }
}


@Component({
  selector: 'app-main-landing',
  templateUrl: './main-landing.component.html',
  styleUrls: ['./main-landing.component.scss']
})
export class MainLandingComponent implements OnInit {
  @ViewChild('scroll', {read: ElementRef}) public scroll!: ElementRef<any>;
  lang: string = 'en';
  storeNames: Array<any> = [];
  display: boolean = true;
  loading: boolean = false;
  themeApplied: boolean;
  seoTitle: string = '';
  seoDesc: string = '';
  isShop: boolean = false;
  isRefreshTokenCalled: boolean = false;
  isRenderApplication: boolean = false;
  bg_color: any;
  cartListCount: any = 0;
  cartListData: any = [];
  public country: string = '';

  constructor(private store: StoreService,
              private cartService: CartService,
              private meta: Meta,
              private messageService: MessageService,
              private mainDataService: MainDataService,
              private translate: TranslateService,
              private router: Router,
              private cookieService: CookieService,
              private authService: AuthService,
              private authTokenService: AuthTokenService,
              private cd: ChangeDetectorRef,
              private tenantService: TenantService,
              private appDataService: AppDataService,
              private languageService:LanguageService,
              @Inject(PLATFORM_ID) private platformId: any,
              private commonService: CommonService) {
    translate.addLangs(['en', 'ar']);
    this.themeApplied = false;

    translate.setDefaultLang('en');


  }

  ngOnInit(): void {

    const sessionId = window.localStorage.getItem('sessionId');
    if (!sessionId) {
      localStorage.setItem('sessionId', GuidGenerator.newGuid());
    }

      this.triggerInitialCalls();

  }

  triggerInitialCalls() {
    this.routeToTop();
    this.createStores();
    // this.getCategories()
    // this.lang = this.store.get('lang') || 'en';
    // remove extra language call
    // this.setLangSettings();
    this.getMainData();

  }

  routeToTop(): void {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  createStores(): void {
    this.storeNames = [


      {name: 'timeInterval', data: null, localStore: true},
      {name: 'orderData', data: null, localStore: true},
      {name: 'transactionData', data: null, localStore: true},
      {name: 'userPhone', data: null, localStore: true},
      {name: 'mainData', data: null, localStore: true},
      {name: 'allCountryTenants', data: null, localStore: true},
      {name: 'tenantId', data: null, localStore: true},
      {name: 'shipmentCost', data: null, localStore: true},
      {name: 'isShop', data: null, localStorage: true},
      {name: 'currency', data: null, localStore: true},
      {name: 'countryCode', data: null, localStore: true},
      {name: 'countryPhone', data: null, localStore: true},
      {name: 'profile', data: null, localStore: true},
      {name: 'categories', data: [], localStore: true},
      {name: 'notifications', data: null, localStore: false},
      {name: 'cartProducts', data: [], localStore: true},
      {name: 'favouritesProducts', data: [], localStore: true},
      {name: 'compareProducts', data: [], localStore: true},
      {name: 'cartProductSuccess', data: null, localStore: false},
      {name: 'favouritesProductSuccess', data: null, localStore: false},
      {name: 'compareProductSuccess', data: null, localStore: false},
      {name: 'allowedFeature', data: null, localStore: false},
      {
        name: 'checkoutData',
        data: {
          shipping: null,
          payment: null,
          promo: null,
          steps: null,
          profile: null,
          orderId: null,
        },
        localStore: true,
      },
      {name: 'search', data: '', localStore: false},
      {name: 'loading', data: false, localStore: true},
      {name: 'verificationCode', data: '', localStore: true},
    ];

    /*Create Dynamic BehaviorSubject at Store*/
    this.storeNames.forEach((item) => {
      this.store.createNewStore(item.name, item.data, item.localStore);
    });
  }

  getConfigurationDecimal() {

    const configuration: ConfigurationResponse = this.appDataService.configuration;
    this.isRenderApplication = true;

    const findAndSetLocalStorage = (key: string): void => {
      const record = configuration.records?.find((x: any) => x.key === key);
      if (record) localStorage.setItem(key, record.value);
    };

    findAndSetLocalStorage(ConfigurationKeys.CurrencyDecimal);
    findAndSetLocalStorage(ConfigurationKeys.CountryPhone);
    findAndSetLocalStorage(ConfigurationKeys.PhoneLength);
    findAndSetLocalStorage(ConfigurationKeys.PhoneNumberMask);
    findAndSetLocalStorage(ConfigurationKeys.Currency);
    findAndSetLocalStorage(ConfigurationKeys.EmailRequired);
    findAndSetLocalStorage(ConfigurationKeys.DisableCents);
    findAndSetLocalStorage(ConfigurationKeys.CustomerAddressLandmarkRequired);

    localStorage.setItem('emailRequired', 'false');
    // localStorage.setItem('disableCent', 'false');

    // Commented out extra API calls
    // this.getCart();
    this.getAllCountryTenants();
    this.getCategories();
    this.isRenderApplication = true;
  }


  getCart(): void {
    let cartData : any = {
      sessionId: localStorage.getItem('sessionId') ?? '',
    };
    let applyTo  = localStorage.getItem('apply-to');
    if(applyTo && applyTo != ''){
      cartData['applyTo'] = applyTo
    }
    this.cartService.getCart(cartData).subscribe({
      next: (res: any) => {
        this.cartListCount = 0;
        this.cartListData = [];
        if (res?.data?.records?.length) {
          this.cartListCount = 0;
          if (res.data.records[0].cartDetails.length) {
            this.cartListCount = res.data.records[0].cartDetails.length;
            this.cartListData = res.data.records[0].cartDetails;

          }
          if (res.data.records[0].cartDetailsDPay && res.data.records[0].cartDetailsDPay.length) {
            this.cartListCount += res.data.records[0].cartDetailsDPay.length;
            this.cartListData = this.cartListData.concat(res.data.records[0].cartDetailsDPay)
          }
          this.mainDataService.setCartLenghtData(this.cartListCount);
          this.mainDataService.setCartItemsData(this.cartListData);

        } else {
          this.mainDataService.setCartLenghtData(0);
          this.mainDataService.setCartItemsData([]);
        }
      },
      error: (err: any) => {
      },
      complete: () => {
      }
    });
  }

  getMainData(): void {
    this.getInitialDataConfig()
  }

  getInitialDataConfig() {
    const initialData: InitialData = this.appDataService.initialData;
    this.getConfigurationDecimal();
    this.handleFeatureByTenant(initialData.featureByTenantRes);
    this.setMainData(initialData.result.records);
    this.setShopDetails(initialData.isShop, initialData.shopProductSetting);

    this.tenantService.setHeader(true);
  }

  private handleFeatureByTenant(featureByTenantRes: any[]): void {
    if (featureByTenantRes.length) {
      localStorage.setItem('Allowed-feature', JSON.stringify(featureByTenantRes));
      const featureList: any = JSON.parse(localStorage.getItem('Allowed-feature') ?? '[]');
      const isSellerHub = featureList.find((item: any) => item.portalName === 'Seller-Hub');

      localStorage.setItem('isSellerHub', isSellerHub ? 'seller-hub' : 'not-seller-hub');
    } else {
      localStorage.setItem('isSellerHub', 'not-seller-hub');
    }

    this.store.set('allowedFeature', featureByTenantRes);
  }

  private setMainData(resultRecords: any): void {
    if (resultRecords !== undefined) {
      this.store.set('mainData', resultRecords);

      Object.entries(resultRecords).forEach(([subkey, value]) => {
        const key = parseInt(subkey);
        const record = resultRecords[key];

        if (record.key === 'AppTheme') {
          const dynamicStyle = record.displayName;
          if (dynamicStyle != null && dynamicStyle !== '') {
            const dynamicStyleObj = JSON.parse(dynamicStyle);
            this.applyStyle(dynamicStyleObj);
          }
        } else {
          this.themeApplied = true;
          this.cd.detectChanges();
        }
      });
    }
  }

  private setShopDetails(isShop: boolean, shopProductSetting: any): void {
    this.isShop = isShop;

    if (this.isShop) {
      this.seoTitle = shopProductSetting.seoTitle;
      this.seoDesc = shopProductSetting.seoDescription;
      this.meta.updateTag({ name: this.seoTitle, content: this.seoDesc });
    } else {
      this.meta.updateTag({
        name: 'Description',
        content: 'MarketPlace, Buy, products',
      });
    }
  }


  getCategories(): void {
    const categories: Categories = this.appDataService.categories;
    if (categories.records != undefined) {
      this.store.set('categories', categories.records);
      localStorage.setItem('allCategories', JSON.stringify(categories.records));
    }
  }

  getAllCountryTenants(): void {
    const tenants = this.appDataService.tenants
    if (tenants.records != undefined) {
      let tenantId = localStorage.getItem('tenantId');
      let data = tenants.records;
      let arr: TenantRecords = data.find((element: any) => element.tenantId == tenantId) ?? new TenantRecords();
      localStorage.setItem('isoCode', arr?.isoCode);
      this.store.set('allCountryTenants', tenants.records);
    }
  }

  appendCssPropertyValue(
    key: any,
    value: any,
    dynamicStyleObj: any,
    sub: boolean
  ) {
    if (value) {
      Object.entries(value).forEach(([subKey, subSubValue]) => {
        if (value[subKey]) {
          if (isPlatformBrowser(this.platformId)) {
            document.documentElement.style.setProperty(
              '--' + subKey,
              value[subKey]
            );
          }
        } else if (subKey && dynamicStyleObj && dynamicStyleObj[key]) {
          this.appendCssPropertyValue(
            subKey,
            dynamicStyleObj[key][subKey],
            dynamicStyleObj,
            true
          );
        }
      });
    }
  }

  applyStyle(dynamicStyleObj: any) {

    Object.entries(dynamicStyleObj).forEach(([key, value]) => {
      if (
        value &&
        dynamicStyleObj[key] &&
        (dynamicStyleObj[key]?.length > 2 ||
          Object.entries(dynamicStyleObj[key])?.length >= 1)
      ) {
        if (
          dynamicStyleObj[key] != null &&
          (dynamicStyleObj[key][0]?.length == 1 ||
            Number(dynamicStyleObj[key][0]))
        ) {
          if (isPlatformBrowser(this.platformId)) {
            document.documentElement.style.setProperty(
              '--' + key,
              dynamicStyleObj[key]
            );
          }

        } else {
          this.appendCssPropertyValue(
            key,
            dynamicStyleObj[key],
            dynamicStyleObj,
            false
          );
        }
      } else {
        if (isPlatformBrowser(this.platformId)) {
          document.documentElement.style.setProperty(
            '--' + key,
            dynamicStyleObj[key]
          );
        }

      }
    });
    this.themeApplied = true;
    this.cd.detectChanges();

  }

  scrollToTop() {
    this.scroll.nativeElement.scrollTop = 0;
  }

  signOut(): void {
    this.commonService.logOut();
    this.router.navigate(['/login']);
    this.getMainData();
  }
}
