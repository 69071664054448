import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-success-info-modal',
  templateUrl: './success-info-modal.component.html',
  styleUrls: ['./success-info-modal.component.scss']
})
export class SuccessInfoModalComponent implements OnInit {
  @Input() displayModal: boolean = false;
  @Input() titleMessage: string = '';
  @Input() bodyMessage: string = '';
  @Input() caution: string = '';

  @Output() cancel = new EventEmitter<boolean>();


  ngOnInit(): void {
    /**/
  }

  onHide() {
    this.cancel.emit(true);
  }
}
