import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {Category} from "@core/interface";
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@environments/environment';
import {StoreService, MainDataService, AppDataService, PermissionService} from "@core/services";
import {Router} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";


@Component({
  selector: 'app-mtn-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  categories: Array<Category> = [];
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  isShowSlider: boolean = environment.isStoreCloud
  ShowCategory;
  leftArrow: boolean = true;
  rightArrow: boolean = true;
  isShop: boolean = false;
  link = 'link';
  isStoreCloud: boolean = environment.isStoreCloud;
  items: any[] = [
    {title: 'Orangies', link: 'https://www.github.com/isahohieku'},
    {title: 'Apple', link: 'https://www.github.com/isahohieku'},
    {title: 'Mango', link: 'https://www.github.com/isahohieku'},
    {title: 'Carrot', link: 'https://www.github.com/isahohieku'}
  ];
  allFeatures: any = [];
  flashSale:any;
  flashSaleData:any;
  topNumber:number = 1000;
  navbarData: any;
  isShowLiveStream: boolean = false

  constructor(
    private store: StoreService,
    private translate: TranslateService,
    private mainDataService: MainDataService,
    private messageService: MessageService,
    private appDataService: AppDataService,
    private router: Router,
    private permissionService: PermissionService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.ShowCategory = false;
  }

  ngOnInit(): void {
    this.isShowLiveStream = this.permissionService.hasPermission('Live-Stream');

    this.getMainData();
    this.getAllFeatures()
  }

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollLeft -= 200
    this.widgetsContent.nativeElement.scrollTo({ right: (this.widgetsContent.nativeElement.scrollLeft), behavior: 'smooth' });
    if (this.widgetsContent.nativeElement.scrollLeft == 0 || this.widgetsContent.nativeElement.scrollLeft < 50) {
      this.leftArrow = true;
    } else {
      this.leftArrow = false;
    }
    this.rightArrow = true;

  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollLeft += 200
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft), behavior: 'smooth' });
    this.leftArrow = false;

    if (this.widgetsContent.nativeElement.scrollLeft > 0) {
      this.leftArrow = false;
    }


  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      this.store.subscription('categories')
        .subscribe({
          next: (res: any) => {
            this.categories = res;


          },
          error: (err: any) => {
            console.error(err);
          }
        });
    }, 1);
  }

  getMainData(): void {
    const initialData = this.appDataService.initialData
    this.isShop = initialData.isShop;
    this.store.set("isShop", this.isShop);
  }

  getAllFeatures() {
   this.navbarData = this.appDataService.layoutTemplate.find((section: any) => section.type === 'navbar')
    this.flashSale = this.appDataService.layoutTemplate.find((section: any) => section.description === 'Flash Sale');
   if(this.flashSale){
     this.flashSaleData=JSON.parse(this.flashSale.data);
   }
    let features = JSON.parse(this.navbarData.data)
    if(this.flashSale?.isActive && this.flashSale?.promotionId && this.flashSaleData.isMainMenu){
      let data:any={id:this.flashSale.promotionId, categoryName:this.flashSaleData.promotionName,type:this.flashSale.type,isActive:this.flashSale.isActive}
      features.push(data);
    }
    if(this.navbarData.isActive) {
      this.allFeatures = features;
    }

  }

  redirectFeaturedProducts(feature: any){
    if(feature.type && feature.type === 'promotion') {
      this.router.navigate([`/promotion/${feature.categoryName}`])
    } else {
      if(feature.categoryIds){
        this.router.navigate([
          `/category/${feature.id}`,
        ]);
      }
      else{
        this.router.navigate([
          `/category/${feature.id}&${this.topNumber}&${feature.categoryName}`,
        ]);
      }
    }


  }

  translated(name: string) {
    let transData: any;
    if (!name) return ""
    name = name?.replace(/\s/g, '');

    this.translate.get('featureType').subscribe((data: any) => {
      transData = data;

    });

    return transData[name?.toLowerCase()] ? transData[name?.toLowerCase()] : name;
  }

  navigateToSellerHub() {
    if (isPlatformBrowser(this.platformId)) {
      window.open(environment.merchantURL +'?tenantId=' +`${localStorage.getItem('tenantId')}`, '_blank');
    }
  }
}
