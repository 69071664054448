import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {StoreService} from "@core/services/store.service";
import {AuthTokenService} from "@core/services/token.service";
import {CookieService} from "ngx-cookie-service";
import {isPlatformBrowser} from "@angular/common";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  isShowSearchBox = new Subject<boolean>();
  constructor(private store: StoreService,
              private authTokenService: AuthTokenService,
              @Inject(PLATFORM_ID) private platformId: any,
              private cookieService: CookieService) {
  }

  logOut(): void {
    this.setStoreData();
    this.authTokenService.authTokenSet('');
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.delete('authToken', window.location.hostname);
    }

    this.store.set('cartProducts', '');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('auth_enc');


    sessionStorage.clear();

    this.store.set('profile', '');
    this.store.set('cartProducts', '');
    localStorage.setItem('sessionId', '');
    localStorage.setItem('addedProducts', '');
  }

  setStoreData(): void {
    if (this.store.localStoreNames.length) {

      this.store.set('refreshToken', null);
      this.store.set('profile', null);
      this.store.set('cartProducts', []);
      this.store.set('favouritesProducts', []);
      this.store.set('compareProducts', []);
      this.store.set('socialAccount', null);
      this.store.set('XXSRFTOKEN', null);
      this.store.set('notifications', {
        notifications: [],
        unreadNotifications: 0,
      });
      this.store.set('checkoutData', {
        shipping: null,
        payment: null,
        promo: null,
        steps: null,
        profile: null,
        orderId: null,
      });
    } else {

      localStorage.setItem('timeInterval', '');
      localStorage.setItem('TenantId', '');
      localStorage.setItem('userPhone', '');
      localStorage.setItem('profile', '');
      localStorage.setItem('cartProducts', JSON.stringify([]));
      localStorage.setItem('favouritesProducts', JSON.stringify([]));
      localStorage.setItem('compareProducts', JSON.stringify([]));
      localStorage.setItem('XXSRFTOKEN', '');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('auth_enc');
    }
  }
}
