<section class="navbar mb-1 header-braedcrum">
  <div class="d-flex justify-content-between" style="width:100%">
    <div class="d-inline-flex flex-row bars-tab" style="width: 70%">
      <ul>
        <li>
          <a>
            <div *ngIf="!isShop">
              <app-mtn-side-menu></app-mtn-side-menu>
            </div>
          </a>
        </li>
      </ul>
      <div class="showCategorySlider">
        <div *ngIf="!leftArrow" class="pull-left mt-sm align-self-center mt-1 ul-mobile">
          <em (click)="scrollLeft()" class="pi pi-angle-left"></em>
        </div>
        <div  class="custom-slider-main">

          <ul style="width:70rem" #widgetsContent>
            <li *ngFor="let feature of allFeatures" class="ul-mobile">
              <span (click)="redirectFeaturedProducts(feature)" [ngClass]="{'extra-pad': isShowSlider}"
                class="feature-label" *ngIf="!feature.shouldNotDisplay">{{feature.categoryName}}</span>
            </li>
          </ul>
        </div>
        <div *ngIf="rightArrow && allFeatures.length > 10"
          class="pull-right mt-sm align-self-center mt-1 ul-mobile pi-white">
          <em (click)="scrollRight()" class="pi pi-angle-right"></em>
        </div>
      </div>
    </div>
    <div class="d-inline-flex flex-row" [ngClass]="{'isShowlivestream': isShowLiveStream}">

      <div>
        <span class="storecloud-button-holder" style="display: inline-flex;">
          <div class="storecloud-live-button"  *ngIf="isShowLiveStream" style="padding-right: 20px;">
            <a class="btn btn_live" routerLink="merchants-livestream">{{'landingNavbar.liveMerchants' | translate}}<span
                class="live-icon"></span></a>
          </div>
          <a (click)="navigateToSellerHub()" *ngIf="isStoreCloud" class="sell-yalla ng-star-inserted">

            <div class="sell-on-yalla cursor-pointer">
              <span class="sell-on-yalla-logo"> </span> {{'landingNavbar.sellOnYallaMall' | translate}}
            </div>




          </a>
          <a (click)="navigateToSellerHub()" *ngIf="!isStoreCloud" class="sell-marketPlace ng-star-inserted">
            <div class="sell-on-marketPlace cursor-pointer">
              <span class="sell-on-marketplace-logo"> </span> {{'landingNavbar.sellOnMarketplace' | translate}}
            </div>
          </a>
        </span>

      </div>
    </div>

  </div>
</section>
