<footer class="footer" *ngIf="desktopView || !isMobileTemplate">
  <div (click)="backToTop()"
    class="footer__to-top cursor-pointer pt-4 pb-4 flex flex-column justify-content-center align-items-center">
    <em class="pi pi-caret-up"></em>

    <div>{{ "footer.backToTop" | translate }}</div>
  </div>
  <div class="footer__content">
    <div id="footer-zoom-container">
      <div class="footer__content-body">

        <div class="d-inline-flex flex-column footer__content-list">
          <div class="footer__content-list__heading">
            {{ "footer.company" | translate }}
          </div>
          <div (click)="reloadCurrentPage(4, 'About us')" class="footer__content-list__details d-none">
            {{ "footer.aboutUs" | translate }}
          </div>
          <div (click)="reloadCurrentPage(5, 'Terms and Conditions')" class="footer__content-list__details">
            {{ "footer.termsAndConditions" | translate }}
          </div>
          <div (click)="reloadCurrentPage(1, 'Shipping and return')" class="footer__content-list__details d-none">
            {{ "footer.shippingAndReturn" | translate }}
          </div>
          <div (click)="reloadCurrentPage(3, 'Privacy policy')" class="footer__content-list__details" >
            {{ "footer.privacyPolicy" | translate }}
          </div>
          <div (click)="reloadCurrentPage(2, 'Conditions of use')" class="footer__content-list__details d-none">
            {{ "footer.conditionsOfUse" | translate }}
          </div>


        </div>
        <div class="d-inline-flex flex-column footer__content-list">
          <div class="footer__content-list__heading">
            {{ "footer.account" | translate }}
          </div>
          <div [routerLink]="'/orders'" class="footer__content-list__details cursor-pointer">
            {{ "footer.myOrders" | translate }}
          </div>
          <div [routerLink]="'/account/address'" class="footer__content-list__details cursor-pointer">
            {{ "footer.myAddress" | translate }}
          </div>
          <div [routerLink]="'/account/details'" class="footer__content-list__details cursor-pointer">
            {{ "footer.myDetails" | translate }}
          </div>


        </div>
        <div class="d-inline-flex flex-column footer__content-list">
          <div class="footer__content-list__heading">
            {{ "footer.help" | translate }}
          </div>
          <div (click)="navigatePage('contact-us')" class="footer__content-list__details cursor-pointer">
            {{ "footer.contactUs" | translate }}
          </div>


        </div>
        <div class="d-inline-flex flex-column footer__content-list">
          <div class="footer__content-list__heading">
            {{ "footer.sellerHub" | translate }}
          </div>
          <div (click)="navigateToSellerHub()" class="footer__content-list__details cursor-pointer">
            {{ "footer.sellOnMarketplace" | translate }}
          </div>
        </div>

        <div class="d-inline-flex flex-column footer__content-list">

          <img *ngIf="!scConfig" alt="No Image" [src]="footerDetails.mainLogo">
          <img *ngIf="scConfig" alt="No Image" src="assets/icons/white-logo.svg">
        </div>


      </div>

    </div>
    <div class="footer__content-body justify-content-end" id="footer-zoom-container">
      <div
        *ngIf="scConfig"
        class="col-12 col-md-3 flex-row justify-content-center"
      >
        <div class="width-icon">
          <div class="d-flex">
            <div class="mx-3" *ngFor="let link of footerLinks">
              <a
                class="nav-link"
                [href]="link.iconLink"
                target="_blank"
              ><em class="{{link.iconClass}}"></em
              ></a>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="footer__content-body" id="footer-zoom-container" *ngIf="!scConfig">
      <div class="footer__content-body__divider footer__content_bottom">
        <div class="d-flex flex-row justify-content-between mt-3">
          <div class="footer__content_bottom__payment-header">
            {{ "footer.weAccept" | translate }}
          </div>
          <div>
            <img alt="No Image" [src]="footerDetails.sideLogo">
          </div>

        </div>
        <div class="d-flex justify-content-between">
          <div class="d-inline-flex flex-row">
            <div *ngFor="let logo of footerDetails.payments" class="footer__content_bottom__payment-logos">
              <img alt="No Image" [src]="logo">
            </div>
          </div>

          <div class="footer__content_bottom__copy-rights">
            {{footerDetails.copyRights}}
          </div>

        </div>

      </div>


    </div>


  </div>
</footer>
<footer class="footer-mobile" *ngIf="!desktopView && !toHide && isMobileTemplate">
  <div (click)="backToTop()"
       class="footer__to-top cursor-pointer pt-4 pb-4 flex flex-column justify-content-center align-items-center">
    <em class="pi pi-caret-up"></em>

    <div>{{ "footer.backToTop" | translate }}</div>
  </div>
  <div class="footer-mobile__content pb-7">
    <div class="footer-mobile__content__top-section d-flex justify-content-between" >
      <div class="footer-mobile__content__top-section__mtn-logo">
        <img *ngIf="!scConfig" alt="No Image" [src]="footerDetails.mainLogoMobile">
        <img *ngIf="scConfig" alt="No Image" src="assets/icons/white-logo.svg">
      </div>
      <button class="footer-mobile__content__top-section__seller_btn" (click)="navigateToSellerHub()">
        {{ "footer.becomeSeller" | translate }}

      </button>
    </div>
    <div class="footer-mobile__body-section">
      <div class="d-inline-flex footer-mobile__body-section__section justify-content-center px-3">
<!--        <div (click)="reloadCurrentPage(4, 'About us')" class="footer-mobile__body-section__section__link">-->
<!--          {{ "footer.aboutUs" | translate }}-->
<!--        </div>-->
        <div (click)="reloadCurrentPage(3, 'Privacy policy')" class="footer-mobile__body-section__section__link" >
          {{ "footer.privacyPolicy" | translate }}
        </div>
        <div (click)="reloadCurrentPage(5, 'Terms and Conditions')" class="footer-mobile__body-section__section__link">
          {{ "footer.termsAndConditions" | translate }}
        </div>
        <div (click)="reloadCurrentPage(1, 'Shipping and return')" class="footer-mobile__body-section__section__link">
          {{ "footer.shippingAndReturn" | translate }}
        </div>
      </div>
      <div class="d-inline-flex footer-mobile__body-section__section justify-content-center">
<!--        <div (click)="reloadCurrentPage(4, 'About us')" class="footer-mobile__body-section__section__link">-->
<!--          {{ "footer.careers" | translate }}-->
<!--        </div>-->
<!--        <div (click)="reloadCurrentPage(2, 'Conditions of use')" class="footer-mobile__body-section__section__link">-->
<!--          {{ "footer.conditionsOfUse" | translate }}-->
<!--        </div>-->
        <div (click)="navigatePage('contact-us')" class="footer-mobile__body-section__section__link">
          {{ "footer.contactUs" | translate }}
        </div>
      </div>
      <div *ngIf="!scConfig" class="d-inline-flex footer-mobile__body-section__section justify-content-center">
      <div *ngFor="let logo of footerDetails.payments" class="footer-mobile__body-section__section">
        <img alt="No Image" [src]="logo">
      </div>
      </div>
      <div class="d-flex flex-column footer-mobile__body-section__section">
            <div class="d-flex justify-content-center footer-mobile__body-section__section__contact-us">
              {{ "footer.reachOut" | translate }}

            </div>
            <div class="d-flex justify-content-center footer-mobile__body-section__section__social-links">
              <div *ngFor="let logo of footerDetails.socials">
                <a [href]="logo.url" target="_blank">
                <img alt="No Image" [src]="logo.icon">
                </a>
              </div>
            </div>
            <div *ngIf="!scConfig" class="d-flex justify-content-center footer-mobile__body-section__section__rights-reserved">
             {{footerDetails.copyRightsMobile}}
            </div>
      </div>
      <div *ngIf="!scConfig" class="d-inline-flex footer-mobile__body-section__section justify-content-center footer-bottom">
        <img alt="No Image" [src]="footerDetails.bottonLogo">
      </div>


    </div>
  </div>

</footer>
