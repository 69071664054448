import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BsModalService} from 'ngx-bootstrap/modal';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GoogleMapsModule} from '@angular/google-maps'

import {InitialModule} from "@shared/modules/initial.module";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoaderInterceptor, AppInterceptor} from '@core/interceptors';
import {MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {MaintenanceComponent} from "@pages/maintenance/maintenance.component";
import {RouteInterceptor} from "@core/interceptors/route.interceptor";
import customFrLocale   from './core/directives/french-fs'
import { LanguageService } from '@core/services';
import { registerLocaleData } from '@angular/common';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getAnalyticsTrackingId():string {
  return localStorage.getItem('GATrackingId')||'';
}
@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en'
    }),
    GoogleMapsModule,
    // SharedModule,
    FormsModule,
    ReactiveFormsModule,
    // LayoutModule,
    InitialModule,
    ToastModule,
    NgxGoogleAnalyticsModule.forRoot(getAnalyticsTrackingId()),
    NgxGoogleAnalyticsRouterModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-N64RPWC6',
    })

  ],
  providers: [
    BsModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RouteInterceptor,
      multi: true,
    },
    MessageService,
    {
      provide: LOCALE_ID,
      useFactory: (translate: LanguageService) => {
          switch (translate.currentLang) {
              case "en":
              case "fr":
                  registerLocaleData(customFrLocale)
                  break;

          }
          return translate.currentLang;
      },
      deps: [LanguageService]
  },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {

  }
}
