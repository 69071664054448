<section class="product-slider card-slider">
  <owl-carousel-o
    (dragging)="carouselDrag($event.dragging)"
    [options]="options"
  >
    <ng-template
      *ngFor="let newProduct of newProduct"
      [width]="products.length < 6 ? 222 : 0"
      carouselSlide
      class="slide"
    >
      <app-mtn-product-card
        style="width:100%"
        [currency]="currency"
        [isDragging]="isDragging"
        [product]="newProduct"
      ></app-mtn-product-card>
    </ng-template>
  </owl-carousel-o>
</section>
