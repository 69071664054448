import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

import {LoaderService} from '../services';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private noLoaders = 0;

  private exceptionURLs = [
    'Product/GetAllProductsByFeatureNew?showLoader=true',
    'Banner/GetBannerById',
    'paginated=true',
    'showLoader=false'
  ];

  constructor(private loaderService: LoaderService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const hasURLMatch = this.exceptionURLs.some(substring => request.url.includes(substring));
    if (!hasURLMatch) {
      this.noLoaders++;
      this.loaderService.show();
    } else {
      this.noLoaders--;
      this.loaderService.hide();
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.noLoaders--;
        if (this.noLoaders <= 0) {
          this.noLoaders = 0;
          this.loaderService.hide()
        }
      }),
    );
  }
}
