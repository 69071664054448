<div class="feature-products__header d-flex justify-content-between">
  <div class="feature-products__header__title">
    {{getTranslatedTitle(title)}}
  </div>
  <div *ngIf="!isMobileView" (click)="redirectFeaturedProducts()" class="feature-products__header__browseAll see-all">
    {{ "landing.seeMore" | translate }}
  </div>
  <div *ngIf="isMobileView" (click)="redirectFeaturedProducts()"
    class="feature-products__header__browseAll see-all-mobile">
    {{ "landing.seeAll" | translate }}
  </div>


</div>


<div *ngIf="showLoader; else showProducts">
  <div class="feature-loader">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>

<ng-template #showProducts>
  <div [ngClass]="{'justify-content-start': fullRowProducts && products.length === 5 ,
'low-products': fullRowProducts && products.length < 5, '': products.length === 8 ,
'best-seller-four-products': !isLeftVerticalBanner && !isNewArrival && !isRightVerticalBanner , 'best-seller-five-products' :isLeftVerticalBanner && !isNewArrival  && !isRightVerticalBanner,
'hot-deals-four-products ': !isRightVerticalBanner && !isNewArrival && !isLeftVerticalBanner, 'hot-deals-five-products' :isRightVerticalBanner && !isNewArrival &&!isLeftVerticalBanner,
'new-arrival-products' : isNewArrival,
}" class="other-products d-flex flex-wrap">

    <a *ngFor="let product of products;let i = index" [ngClass]="{'p-0': fullRowProducts && products.length >= 5}"
      class="d-inline-flex product-card-inner">
      <app-mtn-product-card style="width: 100%" [currency]="currency" [product]="product"></app-mtn-product-card>
    </a>
    <span *ngIf="products.length === 0" class="product-not-found">{{'categories.productNotFound' | translate}}</span>
  </div>
</ng-template>
