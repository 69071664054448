import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { castToHttpParams } from "@core/utilities";

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  baseUrl: string;
  chosenAddress: any = null;
  loadedAddress: boolean = false;
  customAddress = new Subject();
  customMobile = new Subject();
  addresses = new Subject();
  addresseData = this.addresses.asObservable();
  mobile = new BehaviorSubject<any>(false);
  mobileData = this.mobile.asObservable();
  constructor(private http: HttpClient, private translateService: TranslateService) {
    this.baseUrl = `${environment.apiEndPoint}/Tenant/Address`;
  }

  setCustomAddress(data: any) {
    this.customAddress.next(data);
  }

  getCustomAddress() {
    return this.customAddress.asObservable();
  }

  setCustomMobile(data: any) {
    this.customMobile.next(data);
  }

  getCustomMobile() {
    return this.customMobile.asObservable();
  }

  getAddress(): Observable<object> {
    return this.http.get(`${this.baseUrl}/GetCustomerAddress`);
  }
  getAddressById(id: string): Observable<object> {
    return this.http.get(`${this.baseUrl}/GetAddressDetails/${id}`);
  }

  addAddress(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/CreateAddress`, data);
  }

  deleteAddress(id: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/DeleteAddress`, { id: id });
  }

  updateAddress(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/UpdateAddress`, data);
  }

  setDefault(id: string) {
    return this.http.post(`${this.baseUrl}/SetDefaultToAddress`, { id });
  }
  getAddressLabel(data: any) {
    for (let i = 1; i <= data.length; i++) {
      if (!data[i - 1].addressLabel) {
        let selectedLabel = this.translateService.instant('multipleAddress.address') + i
        data[i - 1].addressLabel = selectedLabel
      }
    }
    return data;
  }
  getAllCities(filter: any) {
    return this.http.get(
      `${environment.apiEndPoint}/Shipment/Cities/GetAllCitiesByTenantId?`,
      { params: castToHttpParams(filter) }
    );
  }

  getAllRegions(data: any) {
    return this.http.get(
      `${environment.apiEndPoint}/Tenant/Region/GetAllRegionByTenant?`,
      { params: castToHttpParams(data) }
    );
  }
}

